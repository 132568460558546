@import '../../../styles/scss/abstracts/variables.module';
.appLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background-color: rgba(5, 5, 7, 0.708);
  cursor: not-allowed;

  .loaderContainer {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: color(white);
    box-shadow: -14px 8px 20px 0px rgba(19, 25, 35, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .spinner {
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: conic-gradient(
        color(darkOrange) 0% 50%,
        color(midBlue) 50% 70%,
        transparent 70% 85%,
        color(midPink) 85% 95%,
        color(skyBlue) 95% 100%
      );
      animation: spin 1.5s linear infinite;
    }

    .spinner::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      background: color(white);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    .counter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include textStyle(16px, 600);
      color: color(midBlue3);
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.textRotator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @include textStyle(18px, 600);
  color: color(white);
  margin-top: 65px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade {
  animation: fadeInOut 4s infinite;
}
