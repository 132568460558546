.rc-slider {
  margin-top: 10px !important;
}
.rc-slider-handle-1 {
  bottom: 1.7px !important;
  background-image: radial-gradient(rgb(61, 125, 255) 40%, white 40%) !important;
}
.rc-slider-handle {
  height: 12px !important;
  width: 12px !important;
  border: 2px solid rgb(61, 125, 255) !important;
  background-color: #fff !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
}
.rc-slider-track-1,
.rc-slider-track-2 {
  height: 2px !important;
  background-color: rgb(61, 125, 255) !important;
}
.rc-slider-track-2 {
  height: 2px !important;
  background: url(/assets/images/download.png) white !important;
}
.rc-slider-dot-active {
  border-color: rgb(61, 125, 255) !important;
}
.rc-slider-rail {
  height: 2px !important;
  background-color: #ccc !important;
}
.rc-slider-handle:focus-visible {
  border-color: transparent !important;
  box-shadow: none !important;
}
.react-aria-Checkbox {
  --label-color: var(--spectrum-alias-text-color) !important;
  --deselected-color: gray !important;
  --deselected-color-pressed: dimgray !important;
  --selected-color: slateblue !important;
  --selected-color-pressed: lch(from slateblue calc(l - 10%) c h) !important;
  --checkmark-color: white !important;
  --invalid-color: var(--spectrum-global-color-static-red-600) !important;
  --invalid-color-pressed: var(--spectrum-global-color-static-red-700) !important;

  display: flex !important;
  align-items: center !important;
  gap: 0.571rem !important;
  font-size: 1.143rem !important;
  color: var(--label-color) !important;

  .checkbox {
    width: 1.143rem !important;
    height: 1.143rem !important;
    border: 2px solid var(--deselected-color) !important;
    border-radius: 4px !important;
    transition: all 200ms !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  & svg {
    width: 1rem !important;
    height: 1rem !important;
    fill: none !important;
    stroke: var(--checkmark-color) !important;
    stroke-width: 3px !important;
    stroke-dasharray: 22px !important;
    stroke-dashoffset: 66 !important;
    transition: all 200ms !important;
  }

  &[data-pressed] .checkbox {
    border-color: var(--deselected-color-pressed) !important;
  }

  &[data-selected],
  &[data-indeterminate] {
    .checkbox {
      border-color: var(--selected-color) !important;
      background: var(--selected-color) !important;
    }

    &[data-pressed] .checkbox {
      border-color: var(--selected-color-pressed) !important;
      background: var(--selected-color-pressed) !important;
    }

    & svg {
      stroke-dashoffset: 44 !important;
    }
  }

  &[data-validation-state=invalid] {
    .checkbox {
      border-color: var(--invalid-color) !important;
    }

    &[data-pressed] .checkbox {
      border-color: var(--invalid-color-pressed) !important;
    }

    &[data-selected],
    &[data-indeterminate] {
      .checkbox {
        background: var(--invalid-color) !important;
      }

      &[data-pressed] .checkbox {
        background: var(--invalid-color-pressed) !important;
      }
    }
  }

  &[data-indeterminate] {
    & svg {
      stroke: none !important;
      fill: var(--checkmark-color) !important;
    }
  }

  &[data-focus-visible] .checkbox {
    box-shadow: 0 0 0 2px var(--spectrum-alias-background-color-default), 0 0 0 4px var(--selected-color) !important;
  }

  &[data-disabled] {
    opacity: 0.4 !important;
  }
}

@media (forced-colors: active) {
  .react-aria-Checkbox {
    forced-color-adjust: none !important;

    --label-color: ButtonText !important;
    --deselected-color: ButtonBorder !important;
    --deselected-color-pressed: ButtonBorder !important;
    --selected-color: Highlight !important;
    --selected-color-pressed: Highlight !important;
    --checkmark-color: HighlightText !important;
    --invalid-color: LinkText !important;
    --invalid-color-pressed: LinkText !important;
    --spectrum-alias-background-color-default: Canvas !important;

    &[data-disabled] {
      opacity: 1 !important;
      --deselected-color: GrayText !important;
      --selected-color: GrayText !important;
      --label-color: GrayText !important;
    }
  }
}
