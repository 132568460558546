@import '../../../../../styles/scss/abstracts/variables.module';

.dndItemWithGrabber {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  color: color(manatee);
  transition: color 0.3s ease-in-out;
  background-color: color(white);
  @include textStyle(14px, 500);
  width: 100%;
  max-width: 345px;
  max-height: 48px;
  height: 48px;
  padding: 0 16px;

  cursor: grab;

  &:hover {
    color: color(midBlue);
    svg {
      visibility: visible;
    }
  }

  svg {
    visibility: hidden;
    margin-right: 8px;
  }
}
