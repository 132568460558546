@import '../../../../../styles/scss/abstracts/variables.module';

.addNameInput {
  @include textStyle(32px, 600);
  color: color(black);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 380px;
  height: 60px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::placeholder {
    color: color(gainsboro2);
  }
}

.invalidInput {
  color: color(red);
}

.validInput {
  color: color(midBlue);
}

.addNameInputContainer {
  display: flex;
  flex-direction: column;

  .addNameInput {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.integrationsPanel {
  padding: 15px;
  position: relative;
  margin-bottom: 50px;
  width: 100%;

  .integrationsPanelInner {
    h1 {
      @include textStyle(22px, 600);
      color: color(black);
    }

    p {
      @include textStyle(14px, 400);
    }
  }

  .integrationsPanelContent {
    margin-top: 25px;

    .integrationsPanelSubTitle {
      margin-bottom: 10px;
      h1 {
        @include textStyle(18px, 600);
        color: color(black);
        margin: 0;
      }
    }

    .integrationsPanelSubContent {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin: 15px 0;
    }
  }
}

.welcomeMessage {
  @include textStyle(22px, 500);
  color: color(black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 15%;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: -30px;
  }
}
