@import '../../../../styles/scss/abstracts/mixins.scss';
@import '../../../../styles/scss/abstracts/variables.module';

.fixedScrollbar {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%; /* Scroll bar genişliği */
  height: 15px;
  max-height: 15px;
  min-height: 15px;
  z-index: 1000;
  display: flex;
  flex-direction: row;

  .horizontalSpacer {
    width: 460px;
    height: 15px;
    max-height: 15px;
    min-height: 15px;
    background-color: transparent;
  }
}

.horizontalViewPort {
  width: 100%;
  height: 15px;
  max-height: 15px;
  min-height: 15px;
  background-color: transparent;
  overflow: auto;
  background-color: color(lightGrey);
  scrollbar-color: color(grey) color(lightGrey) !important;
}

.horizontalViewPortInner {
  height: 15px;
  max-height: 15px;
}

.hideScrollBar {
  @include hideScrollbar;
}
