@import '../../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../../styles/scss/abstracts/mixins';

.emptyRowSidebarCellTr {
  position: relative;
  width: cellConfig(sidebarCellWidth);

  span {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100px;
    right: -100px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent);
    opacity: 0.2;
    pointer-events: none; // Ensure this element doesn't interfere with any mouse events
  }
}

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
}

.stickySidebarCell {
  position: sticky;
  left: -1px;
  z-index: 100;
  background-color: white;
  border-right-width: 1px !important;
  min-width: cellConfig(sidebarCellWidth);
  max-width: cellConfig(sidebarCellWidth);
  width: cellConfig(sidebarCellWidth);
}

.stickyColumn {
  position: sticky;
  left: -1px;
  z-index: 100;
  background-color: white;
  border-right-width: 1px !important;
  min-width: cellConfig(sidebarCellWidth);
  max-width: cellConfig(sidebarCellWidth);
  width: cellConfig(sidebarCellWidth);
}

.topLeftCell {
  @extend .stickyColumn;
  font-size: 22px !important;
  padding: 0 24px !important;
  border-right-width: 1px !important;
  z-index: 2;
  height: 56px;
  max-height: 56px;
  width: cellConfig(sidebarCellWidth);
  max-width: cellConfig(sidebarCellWidth);
}

.departmentHeaderCell {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    width: 1px;
    background-color: color(lavendeGray);
  }
}

.sideBarCell {
  border-collapse: collapse;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1.25em;
  min-width: cellConfig(sidebarCellWidth);
  position: relative;
  @include textStyle(14, 500);
  color: color(black);

  @include add-left-border;

  .actions {
    opacity: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 3px;

    // gap: 0.75em;
    img,
    button {
      object-fit: contain;
      object-position: center;
      position: relative;
      margin: 0;
      height: 1em;
      width: 1em;
    }
  }

  /**
   When hovering over a table subrow, three dots and copy icons appear as a hover effect. 
   However, when a submenu is opened, we need to avoid removing this hover effect. 
   By adding a class named 'unHoverable' to the element when it is hovered, we prevent the hover effect. 
   This ensures that the icons remain visible, i.e., they do not disappear while the menu is open.
   */
  .unHoverable {
    opacity: 1;
  }

  .departmentName {
    font-weight: 400;
    border-top-width: 0px !important;
    font-size: 14px;
    color: color(manatee);
    width: calc(
      cellConfig(sidebarCellWidth) - cellConfig(sidebarAdditionalWidthHP)
    );
    text-align: left;
    margin-right: 5px;
    @extend .elipsis;
  }

  .subheader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    .toggleSubrowsButton {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transform: rotate(270deg);
        transition: 100ms;
        color: color(manatee);
      }
      &.expanded {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }

  button {
    background: transparent;
    border-radius: 2px;
    border: 0;
    outline: none;
    font-weight: 500;
    height: 1em;
    width: 1em;
    font-size: 1.5em;
    padding: 0;
    text-align: center;
    line-height: 0;
    opacity: 0.7;
  }

  .purple {
    @apply text-tablePurple;
  }

  .bold {
    font-weight: 600;
  }
}

.sideBarCellChild {
  @extend .sideBarCell;
  transition: all 100ms ease-in-out;
  position: relative;

  &:hover {
    background-color: color(lightBlue4);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0px;
      height: 100%;
      width: 1px;
      background-color: color(lavendeGray);
    }

    .actions {
      opacity: 1;
    }
  }

  .hoverable {
    svg {
      color: color(black);
      transition: all 100ms ease-in-out;

      &:hover {
        color: color(midBlue);
      }
    }
  }

  .hoverable:hover {
    cursor: pointer;
  }
}

/**
   When hovering over a table subrow, three dots and copy icons appear as a hover effect. 
   However, when a submenu is opened, we need to avoid removing this hover effect. 
   By adding a class named 'unHoverable' to the element when it is hovered, we prevent the hover effect. 
   This ensures that the icons remain visible, i.e., they do not disappear while the menu is open.
   */
.unHoverable {
  opacity: 1;
}

.modelNameInput {
  border: none !important;
  background-color: transparent !important;
  width: 100%;
  outline: none;
}

.draggingRow {
  height: auto !important;
}

.disabledTable {
  position: sticky;
  z-index: 200 !important; // Changed to lower z-index to prevent overlapping the header
  background-color: white;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: color(disabledModeColor);
    z-index: 1;
  }
}

.bodyCell {
  border: 1px solid color(lavendeGray);
  min-width: cellConfig(cellWidth);
  position: relative;
}

.empty {
  border: 1px solid color(lavendeGray);
  height: 24px;
  z-index: 20;
  border-top-width: 0px !important;
  min-width: cellConfig(cellWidth);
}

.sidebarCellContent {
  width: calc(
    cellConfig(sidebarCellWidth) - cellConfig(sidebarAdditionalWidthFinancials)
  );
  @extend .elipsis;
  text-align: left;
}

.sidebarCellContentContainer {
  color: color(manatee);
  display: flex;
  align-items: center;
}

.modelsSidebarCellContent {
  width: max-content;
  @extend .elipsis;
  text-align: left;
  white-space: nowrap;
}
