// Import colors from TypeScript file
@import '../../styles/scss/abstracts/variables.module';
// main.scss

@mixin titleStyle() {
  font-weight: bold;
  border-bottom: 1px solid color(tableBorderColor);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.popover {
  background-color: color(white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  z-index: 1001;
  flex-direction: column;
  width: 200px;
  font-size: 12px;
  gap: 0.5rem;
  &Title {
    @include titleStyle();
  }

  .sectionsContainer {
  }

  .settingsContainer {
    border-top: 1px solid color(tableBorderColor);

    display: flex;
    justify-content: flex-start;
    align-items: left;
    margin-top: auto; // Push it to the bottom of the popover
    padding: 13px 5px 5px 10px;
    border-bottom: none !important;
    border-radius: 1000 !important;
    // add hover effect

    .settingsIcon {
      height: 1rem;
      width: 1rem;
      fill: currentColor; // Use the text color
      padding-left: 2px;
    }

    .settingsLabel {
      margin-left: 0.5rem;
      // center it vertically
      display: flex;
      // center it vertically
      align-items: center;

      color: gray;
    }
  }
}

.section {
  gap: 0.5rem;
  padding-bottom: 8px;

  &Title {
    @include titleStyle();
  }

  .optionsList {
    margin: 0;
    border: none !important;
    box-shadow: none !important;
    list-style: none;
  }
}

.toggleOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  .grabIcon {
    cursor: grab;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:hover {
    background-color: transparent !important;
    color: color(black);

    .grabIcon {
      opacity: 1;
    }
  }

  @mixin hover() {
    padding-left: 0px;
    padding-right: 0px;
    &:hover {
      background-color: color(tableBorderColor);
    }
  }

  .toggleInput {
    margin-right: 0.5rem; // Space between the toggle and the label
  }

  .toggleLabel {
  }
}
