@import '../scss/abstracts/variables.module';
@import '../scss/helper.scss';
.apexcharts-tooltip-title {
  background-color: color(white) !important;
  border: 0px !important;
  margin-bottom: 0px !important;
}
.apexcharts-tooltip {
  background-color: color(white) !important;
  box-shadow: 0px 4px 24px 0px color(darkBlack2) !important;
  border-radius: 12px !important;
  padding: 12px !important;
  @extend .fs-18-400;
}
.apexcharts-tooltip-marker {
  height: 12px !important;
  width: 12px !important;
  border-radius: 2px !important;
}
.apexcharts-inner,
.apexcharts-grid {
  width: 540px !important;
}
.apexcharts-tooltip-series-group {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: color(white) !important;
}

.apex-chart-tooltip-box {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.apex-chart-color-box {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
// .apexcharts-graphical{
//   transform: translate(0,0) !important;
// }
