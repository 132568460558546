.react-aria-Select {
  --border-color: var(--spectrum-alias-border-color);
  --border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);

  .react-aria-Button {
    color: var(--text-color);
    background: var(--spectrum-global-color-gray-50);
    border: 1px solid var(--border-color);
    box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    appearance: none;
    vertical-align: middle;
    font-size: 1.072rem;
    margin: 0;
    outline: none;
    display: flex;
    align-items: center;
    max-width: 250px;

    &[data-focus-visible] {
      border-color: var(--focus-ring-color);
      box-shadow: 0 0 0 1px var(--focus-ring-color);
    }

    &[data-pressed] {
      background: var(--spectrum-global-color-gray-150);
    }

    &:disabled {
      border-color: var(--border-color-disabled);
      color: var(--text-color-disabled);
      & span[aria-hidden] {
        background: var(--border-color-disabled);
      }

      .react-aria-SelectValue {
        &[data-placeholder] {
          color: var(--text-color-disabled);
        }
      }
    }
  }

  .react-aria-SelectValue {
    &[data-placeholder] {
      font-style: italic;
      color: var(--spectrum-global-color-gray-700);
    }

    & [slot='description'] {
      display: none;
    }
  }

  & span[aria-hidden] {
    width: 1.5rem;
    line-height: 1.375rem;
    margin-left: 1rem;
    padding: 1px;
    color: white;
    border-radius: 4px;
    font-size: 0.857rem;
  }

  [slot='description'] {
    font-size: 12px;
  }

  [slot='errorMessage'] {
    font-size: 12px;
    color: var(--spectrum-global-color-red-600);
  }
}

.react-aria-ListBox {
  --highlight-background: rgba(255, 255, 255, 0.8);
  --highlight-foreground: white;
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);

  max-height: inherit;
  box-sizing: border-box;
  overflow: auto;
  padding: 2px;
  outline: none;

  .react-aria-Section:not(:first-child) {
    margin-top: 12px;
  }

  .react-aria-Header {
    font-size: 1.143rem;
    font-weight: bold;
    padding: 0 0.571rem 0 1.571rem;
  }

  .react-aria-Item {
    margin: 2px;
    padding: 0.286rem 0.571rem 0.286rem 1.571rem;
    border-radius: 6px;
    outline: none;
    cursor: default;
    color: var(--text-color);
    font-size: 1.072rem;
    position: relative;
    display: flex;
    flex-direction: column;

    &[aria-selected='true'] {
      font-weight: 600;

      &::before {
        content: '✓';
        content: '✓' / '';
        alt: ' ';
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }

    &[data-focused],
    &[data-pressed] {
      background: var(--highlight-background);
    }

    &[aria-disabled] {
      color: var(--text-color-disabled);
    }

    [slot='label'] {
      font-weight: bold;
    }

    [slot='description'] {
      font-size: small;
    }
  }
}

.react-aria-Popover {
  --background-color: white;
  --border-color: var(--spectrum-global-color-gray-400);

  border: 1px solid var(--border-color);
  min-width: var(--trigger-width);
  max-width: 250px;
  box-sizing: border-box;
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 6px;
  background: var(--background-color);
  outline: none;

  &[data-placement='top'] {
    --origin: translateY(8px);
  }

  &[data-placement='bottom'] {
    --origin: translateY(-8px);
  }

  &[data-entering] {
    animation: slide 200ms;
  }

  &[data-exiting] {
    animation: slide 200ms reverse ease-in;
  }
}

@keyframes slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (forced-colors: active) {
  .react-aria-Select {
    --border-color: ButtonBorder;
    --border-color-disabled: GrayText;
    --text-color: ButtonText;
    --text-color-disabled: GrayText;
    --focus-ring-color: Highlight;

    .react-aria-Button:disabled span[aria-hidden] {
      background: transparent;
    }
  }

  .react-aria-ListBox {
    forced-color-adjust: none;

    --highlight-background: Highlight;
    --highlight-foreground: HighlightText;
    --border-color: ButtonBorder;
    --background-color: ButtonFace;
    --text-color: ButtonText;
    --text-color-disabled: GrayText;
  }
}

.chart-wrapper:hover .select-menu {
  visibility: visible;
}
.chart-wrapper .select-menu {
  visibility: hidden;
}
.visible-imp {
  visibility: visible !important;
  position: relative;
  top: -10px;
}
