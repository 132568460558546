.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
}
.static-tag {
  padding: 4px 0;
}

.tag-remove {
  margin-left: 4px;
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
}
input {
  border: none;
  outline: none;
  flex-grow: 1;
  height: 24px;
}
ul {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 0px;
  list-style: none;
}
ul li {
  padding: 10px;
}
ul li:hover {
  color: #fff;
  cursor: pointer;
  background: #1c86f2;
}
.formula-parent{
  width: 200px;
  height: 75px;
  overflow: auto;
  cursor: pointer;
}
.formula-parent:hover .formula-chart{
  display:none;
}
.formula-parent .formula-chart{
  display:block;
}
.formula-parent:hover .formula-section{
  display:flex;
  justify-content: center;
  align-items: center;
  height:inherit;
}
.formula-parent .formula-section{
  display:none;
}