@import '../../../styles/scss/abstracts/variables.module';

.dropdown {
  /* @apply relative inline-block text-left z-50; */
  @apply relative; /* Only need relative position */
}
.dropdownButton {
  /* styling for the button */
  background-color: white; /* example to make the button red */
}
.dropdownList {
  border: none;
  box-shadow: none;
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-color: color(periwinkleGray) white;
}

.dropdownHeader {
  padding: 10px 0;
  padding-bottom: 4px;
  @apply flex items-center cursor-pointer;
  border-top: 1px solid color(carbon400);
  width: 100%;
  background-color: white;

  &:hover {
    @apply bg-gray-100;
  }
}

.headerName {
  @apply ml-2;
  font-family: 'Figtree';
  font-size: 16px;
  font-weight: 400;
}

.addButton {
  @apply bg-transparent flex items-center;
  color: color(white);
  background-color: color(midBlue);
  @include textStyle(14px, 500);
  padding: 8px 0.5rem;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.searchContainer {
  @apply flex items-center gap-3 p-2;
  border-bottom: 1px solid color(carbon400);
  background-color: white;

  svg {
    color: color(carbon400) !important;
    cursor: pointer;
  }
}

.dropdownItem {
  @apply relative flex items-center justify-between cursor-pointer;
  background-color: transparent;
  padding: 0;

  &:hover {
    @apply bg-gray-100;
  }

  input {
    font-weight: 400;
    padding: 20px 8px;
    background-color: transparent;
    border-bottom: 1px solid color(darkgrayText);
  }
}

.dropdownOptions {
  @apply flex items-start justify-start flex-col;

  &:hover {
    @apply text-blue-100;
  }
}

.linkBtn {
  @apply flex items-center gap-2 cursor-pointer;
  color: $global-header-color-deepNavy;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  width: 100%; /* Ensure full width */
  justify-content: flex-start; /* Align items to the left */
  white-space: nowrap; /* Prevent text from wrapping */
}

.linkOption {
  @apply flex items-center gap-2 cursor-pointer;
  color: $global-header-color-deepNavy;
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  text-decoration: none;
  border: none;
  width: 100%;
  height: 100%;
  justify-content: flex-start; /* Align items to the left */
  white-space: nowrap; /* Prevent text from wrapping */
  &:hover {
    border: none;
    text-decoration: none;
  }
}

.active {
  font-weight: 700;
}

.popupContent {
  width: fit-content;
  height: fit-content;
  overflow-y: auto;
  min-width: 300px;
  padding: 0 5px;
  position: relative;
  z-index: 1001;
}
