@import '../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../styles/scss/abstracts/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 12px;
  margin-bottom: 48px;

  .stageTitle {
    @include textStyle(24, 600);
    color: color(black);
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  .addProductButton {
    align-self: flex-end;
    background-color: color(white);
    color: color(black);
    border: 1px solid color(periwinkleGray);
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
    @include textStyle(12, 500);
    cursor: pointer;
    margin-right: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .table {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;

    scrollbar-color: color(periwinkleGray) transparent;

    .tableHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: max-content;

      .tableHeaderCell {
        @include textStyle(12px, 600);
        color: color(black);
        line-height: 1.5;
        padding: 0 10px;
        display: flex;
        align-items: center;
        white-space: pre-line;
      }
    }

    .tableBody {
      width: max-content;
      .tableRow {
        display: flex;
        align-items: center;

        .tableCell {
          @include textStyle(12px, 400);
          color: color(black);
          box-sizing: border-box;

          .input {
            display: flex;
            align-items: center;
            width: 100%;
            height: 46px;
            border: 1px solid transparent;
            padding: 10px;
            padding-right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .emptyRow {
        height: 10px;
        background-color: color(culturedGray);
        border: 1px solid color(lavendeGray);
        border-top: 0;
        border-bottom: 0;
        width: auto;
        margin-left: 30px;
      }
    }
  }
}

.actionColumn {
  width: 30px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;

  svg {
    outline: none;
    color: color(colorzinc400);
    &:hover {
      cursor: pointer;
      color: color(red);
    }
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  width: 100%;
  padding: 30px;
  padding-right: 17px;
  padding-top: 0;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color(white);
  }
}

.errorInput {
  border: 1px solid color(red) !important;
  background-color: rgba(color(red), 0.05) !important;
}

.errorText {
  color: color(red);
}
