@import '../../../../../styles/scss/abstracts/variables.module';

.templateLibraryHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    @include textStyle(16px, 800);
    color: color(charcoalGray);
    margin-bottom: 10px;
  }
}

.noTemplates {
  @include textStyle(14px, 500);
  width: 100%;
  padding: 30px 0;
  color: color(charcoalGray);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchTemplatesInput {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  .searchIcon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: color(carbon400);
  }

  .clearIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: color(carbon400);
    cursor: pointer;
  }

  input {
    border: 1px solid color(lavendeGray);
    padding: 10px 16px 10px 38px;
    height: 34px;
    width: 298px;
    border-radius: 24px;
    @include textStyle(14px, 500);
  }
}
