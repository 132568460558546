$breakpoints: (
  'all': null,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
);

$theme-colors: (
  'fff': #fff,
  'a0a096': #a0a096,
);

.input-text {
  border: 1px solid #cccccc;
  padding: 5px;
  border-radius: 10px;
  min-width: 215px;
}
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   // border: none;
//   // outline: none;
//   text-decoration: none;
//   // list-style: none;
// }

$fontweights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
  bold: bold,
  bolder: bolder,
  lighter: lighter,
);

@each $breakpoint, $width in $breakpoints {
  @for $i from 0 through 100 {
    @if $breakpoint == 'all' {
      .border-radius-#{$i} {
        border-radius: $i * 0.063rem !important;
      }

      .fs-#{$i} {
        font-size: $i * 1px !important;
        // font-size: $i * 0.063rem !important;

        // @media (max-width: 576px) {
        //   font-size: $i * 0.046rem !important;
        // }
      }
      // Font size and font weight class
      @each $weight-name, $weight-value in $fontweights {
        .fs-#{$i}-#{$weight-name} {
          font-size: $i * 1px !important;

          @if $weight-name
            !=bold and
            $weight-name
            !=bolder and
            $weight-name
            !=lighter
          {
            font-weight: $weight-value;
          } @else {
            font-weight: $weight-name;
          }
        }
      }
    }
  }
}

@each $color, $color-code in $theme-colors {
  .bg-#{$color} {
    background-color: $color-code;
  }
  .border-#{$color} {
    border: 1px solid $color-code;
  }

  .text-#{$color} {
    color: $color-code;
  }
}

.cursor-pointer {
  cursor: pointer;
}
