@import '../../../../styles/scss/abstracts/variables.module';

.tableOptionsSection {
  width: 100%;
  background-color: color(white);
  border-top: 1px solid color(lavendeGray);
  padding: 16px 28px;
  display: flex;
  align-items: center;
  gap: 20px;

  .toggleEyeOption {
    border: 1px solid color(selectBorderColor);
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    color: color(black);
    cursor: pointer;

    span {
      @include textStyle(14px, 500);
    }
  }

  .disabledOption {
    border-color: color(selectBorderColor);
    color: color(duskGray);
  }
}
