.graphCell {
  max-height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: relative;
  z-index: 0;
  max-width: 160px;
  overflow: hidden;

  .graphContainer {
    padding-top: 15px;
    width: 125px;
    height: max-content;
  }
}
