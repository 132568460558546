@keyframes animate_border {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animatedBorder {
  position: relative;
  z-index: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  display: inline-flex;
  padding: 3px;
  overflow: hidden;
  @apply ml-10 rounded-md mt-4;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      115deg,
      transparent,
      transparent,
      gold,
      transparent,
      transparent
    );
    background-size: 50% 100%;
    border-radius: inherit;
    animation: animate_border 2s linear infinite;
  }
}
