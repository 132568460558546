@import '../../../../styles/scss/abstracts/variables.module';

.financialDrawerContent {
  background-color: transparent;
  .financialDrawerContentHeaderWrapper {
    border-radius: 24px 24px 0px 0px;
    background-color: transparent;
    overflow: hidden;
  }
  .financialDrawerContentHeader {
    background-color: color(midBlue);
    padding: 20px 14px 18px 14px;
    color: color(white);

    h1 {
      @include textStyle(24px, 400);
    }

    p {
      @include textStyle(14px, 700);
    }

    .financialDrawerContentHeaderSearch {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        top: 65%;
        transform: translateY(-65%);
        left: 10px;
        color: color(gray);
      }

      input {
        width: 100%;
        border-radius: 32px;
        padding: 20px 40px;
        color: color(black);
        @include textStyle(16px, 400);
        margin-top: 10px;
      }
    }
  }

  .financialDrawerTabs {
    ul {
      padding: 0 10px !important;
      box-shadow: none !important;
      gap: 8px;
      border-bottom: 1px solid color(grayBorder3);

      .financialDrawerTab {
        @include textStyle(14px, 400);
        color: color(manatee);
        width: 177px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid transparent;

        &:hover {
          background-color: color(white) !important;
          color: color(black2024);
        }

        &[aria-selected='true'] {
          color: color(black2024);
          border-bottom: 2px solid color(midBlue);
        }
      }
    }
  }

  .financialDrawerSubTabs {
    ul {
      padding: 15px 10px !important;
      box-shadow: none !important;

      li {
        @include textStyle(14px, 500);
        padding: 5px 8px 5px 8px !important;
        background-color: color(grayBg2);
        border-radius: 6px;
        color: color(black2024);
        border: 1px solid color(gainsboro2);
        width: auto;
        height: auto;
        transition: all 0.3s ease;

        &[aria-selected='true'] {
          background-color: color(periwinkleFrost) !important;
          border-color: color(midBlue) !important;
        }
      }
    }
  }
}

.mainDrawerContentContainer {
  height: calc(100vh - 290px);
  overflow-y: auto;
}

.transactions {
  .transactionsHeader {
    background-color: color(grayBg2);
    padding: 28px 28px 28px 28px;

    h1 {
      @include textStyle(15px, 500);
      color: color(black);
    }

    span {
      @include textStyle(15px, 500);
      color: color(black);
    }
  }

  .transactionsSubHeader {
    background-color: color(lightwhite3);
    cursor: pointer;
    padding: 10px 24px 10px 24px;
    border-bottom: 1px solid color(grayBorder3);

    h1 {
      @include textStyle(15px, 500);
      color: color(black);
    }

    span {
      @include textStyle(15px, 500);
      color: color(grayColor3);
    }
  }

  .transactionsItem {
    padding: 12px 28px 12px 28px;
    border-bottom: 1px solid color(grayBorder3);

    span {
      @include textStyle(12px, 500);
      color: color(grey);
    }

    .transactionsItemName {
      @include textStyle(14px, 500);
      color: color(black);
      width: 75%;
    }

    p {
      @include textStyle(14px, 400);
      color: color(black);
    }

    .transactionsItemMetaDataDetails {
      margin-top: 10px;
      .splitAndMemoEntrySection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;

        .memoEntrySection {
          display: flex;
          @include textStyle(12px, 500);
          gap: 4px;

          span {
            width: 100%;
          }
        }

        svg {
          color: color(grey);
        }
        svg[aria-label='split'] {
          transform: rotate(90deg);
        }
      }

      .originEntrySection {
        padding: 5px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        color: color(gray);
      }
    }
  }

  .hoveredTransactionItem {
    cursor: pointer;
    &:hover {
      .transactionsItemName {
        color: color(midBlue);
        text-decoration: underline;
      }

      p {
        color: color(midBlue);
        text-decoration: underline;
      }
    }
  }
}

.tooltipContent {
  background-color: white;
  backdrop-filter: blur(25px);
  border: none;
  width: fit-content;
  padding: 5px 10px;
  height: fit-content;

  button {
    background-color: transparent;
    border: none;
    cursor: default;
    @include textStyle(14px, 500);
    color: color(black);
    transition: all 0.3s ease;
  }

  .linkButton {
    color: color(midBlue);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.drawerBodySection {
  width: 100%;

  .drawerBodySectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 8px 24px;
    background-color: color(grayBg2);
    border-top: 1px solid color(lavendeGray);
    border-bottom: 1px solid color(lavendeGray);
    cursor: pointer;

    span {
      color: color(black2024);
      cursor: pointer;
      @include textStyle(14px, 500);
    }

    svg {
      color: color(manatee);
      cursor: pointer;
    }
  }
}

.financialDrawerTabPanelContainer {
  height: calc(-290px + 100vh);
  overflow: scroll;
}
