.triple-spinner {
    display: block;
    position: absolute;
    width: 125px;
    height: 125px;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid rgba(61, 125, 255, 1);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
}

.triple-spinner::before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: rgba(61, 125, 255, 0.9);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3.5s linear infinite;
}

.triple-spinner::after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: rgba(61, 125, 255, 0.8);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.75s linear infinite;
}



@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}