@import '../../../styles/scss/abstracts/variables.module';
.customSelectInput {
  border-radius: 6px;
  height: 45px;
  max-height: 45px;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 80%);

  input {
    height: 100% !important;
    background-color: color(white);
    color: color(black);
  }
}
