.cellContainer {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  div {
    width: 100%;
  }

  input {
    padding: 0px 12px;
    text-align: end;
    min-height: auto !important;
  }

  #dataCellPicker {
    padding: 0px 12px !important;
  }
}
