@import '../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../styles/scss/abstracts/mixins';

.dropMiddle {
  position: relative;
  z-index: 99999;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: cellConfig(cellHeight);
    width: cellConfig(sidebarCellWidth);
    opacity: 0.3;
    background-color: color(midBlue);
  }
}

.basedropline {
  width: cellConfig(sidebarCellWidth);
  position: absolute;
  left: 0;
  color: color(midBlue);
  display: flex;
  align-items: center;
  padding: 0 10px;
  opacity: 0.8;
  z-index: 99999;

  svg {
    margin: -33px -33px;
  }
}

.topdropline {
  @extend .basedropline;
  top: -3px;
}

.bottomDropLine {
  @extend .topdropline;
  top: auto;
  bottom: -1px;
}

.dropLine {
  width: 100%;
  height: 4px;
  background-color: color(midBlue);
}
