@tailwind base;
@import '../styles/scss/abstracts/mixins';
@import '../styles/scss/abstracts/variables.module';

a {
  @apply text-blue-700;
}

a:hover {
  @apply border-b-2 border-blue-700;
}

@tailwind components;

@tailwind utilities;

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

body,
html {
  font-family: 'Figtree';
  height: 100%;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-family: 'Figtree' !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-family: 'Figtree' !important;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-family: 'Figtree' !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Figtree' !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'Figtree' !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  font-family: 'Figtree' !important;
}

* {
  scrollbar-color: color(manatee) color(lightestGrey);
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 30;
}
/* scroll between graphs and table */
.scroll-wrapper {
  position: relative;
}
/* .scroll-wrapper-inner {
  position: sticky;
  top: 120px;
}
.scroll-fixed {
  position: absolute;
  top: 150px;
} */

button {
  outline: none;
}

.scrollable {
  position: relative;
}

.scrollable {
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
  position: relative;
  height: auto;
  z-index: 10;
}

/* Custom classes for dashboard grid */
.react-grid-item {
  transition: all 0.2s ease;
  transition-property: left, top;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #eef0f2;
  background: var(--scrollbar-track-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #eef0f2;
  background: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

.form-control-input {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 216 224 / var(--tw-border-opacity));
}
.react-aria-Popover {
  background-color: white !important;
}

.react-aria-Tabs {
  --highlight-color: slateblue;
  --text-color: var(--spectrum-global-color-gray-700);
  --text-color-hover: var(--spectrum-global-color-gray-800);
  --text-color-selected: var(--spectrum-global-color-gray-900);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);

  display: flex;

  &[data-orientation='horizontal'] {
    flex-direction: column;
  }

  &[data-orientation='vertical'] {
    flex-direction: row;
  }
}

.react-aria-TabList {
  display: flex;

  &[aria-orientation='vertical'] {
    flex-direction: column;
    border-right: 1px solid gray;

    .react-aria-Tab {
      border-right: 3px solid var(--border-color, transparent);
    }
  }
}

.react-aria-Tab {
  padding: 10px;
  cursor: default;
  outline: none;
  position: relative;
  color: rgba(145, 157, 175, 1);
  transition: color 200ms;

  &[data-hovered],
  &:focus {
    color: rgba(0, 17, 51, 1);
    cursor: pointer;
  }

  &[aria-selected='true'] {
    background-color: rgba(246, 248, 250, 1);
    color: rgba(0, 17, 51, 1);
  }

  &[aria-disabled] {
    color: var(--text-color-disabled);
    &[aria-selected='true'] {
      --border-color: var(--text-color-disabled);
    }
  }

  &[data-focus-visible]:after {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 4px;
    border: 2px solid var(--highlight-color);
  }
}

.react-aria-TabPanel {
  margin-top: 4px;
  padding: 10px;
  border-radius: 4px;
  outline: none;

  &[data-focus-visible] {
    box-shadow: inset 0 0 0 2px var(--highlight-color);
  }
}

@media (forced-colors: active) {
  .react-aria-Tabs {
    forced-color-adjust: none;
    color: CanvasText;

    --highlight-color: Highlight;
    --text-color: ButtonText;
    --text-color-hover: ButtonText;
    --text-color-selected: ButtonText;
    --text-color-disabled: GrayText;
  }
}

.customBorder {
  height: 4px;
  background-image: url(/assets/images/download.png);
  background-repeat: repeat-x;
}
.stickyN {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky:before,
.sticky:after {
  content: '';
  display: table;
}

.c-react-aria__dialog {
  padding: 10px 15px;
}

.c-react-aria__popup {
  position: fixed;
  inset: 0;
}

.year-calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #eef0f2;
}

.months_selection_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: 'center';
  width: 200px;
}

.c-datepicker {
  display: inline-flex !important;
  flex-direction: column !important;
}

.c-datepicker__date-field-container {
  position: relative;
}

.addtional-menu-item {
  font-size: 12px;
}

.hiring_header {
  width: 160px;
}

.hiring_main_name_cell {
  width: 256px;
}

.model_formula_cell {
  width: 156px;
}

.financial_header_cell_wrapper {
  width: 104px;
}

.financial_header_cell_border {
  width: 107px;
  top: -1;
}

.menu_wrapper {
  position: relative;
  display: flex;
}

.menuItem_styling {
  background: transparent;
  z-index: 999;
  outline: none;
  cursor: pointer;
  min-width: 100px;
}

.font-size-md {
  font-size: 14px;
}

.c-datepicker__date-field-container {
  position: relative;
}

.popover_styling {
  background: white !important;
  box-shadow:
    hsl(206deg 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206deg 22% 7% / 20%) 0 10px 20px -15px !important;
}

.tab_styling {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.hiring_roles_column_container {
  width: calc(25% - 1rem);
}

.box-shadow {
  box-shadow: '0 30px 40px rgba(0,0,0,.1)';
}

.popup-overlay {
  @apply overflow-auto;
}

.EZDrawer__checkbox {
  opacity: 0;
  position: absolute !important;
}

@media screen and (max-width: 1500px) {
  .apexcharts-svg {
    width: 101%;
  }
}

.ReactCollapse--collapse {
  transition: height 400ms;
}

.apexcharts-legend-marker {
  border-radius: 50% !important;
  margin-right: 12px !important;
}

.apexcharts-legend-text {
  @include textStyle(14, 400);
  white-space: nowrap;
  color: color(manatee) !important;
}
