@import './abstracts/variables.module';
@import './abstracts/mixins';

.drawer {
  width: 330px !important;
  max-width: 330px !important;
  background-color: color(copilotLightColor) !important;
  box-shadow: -14px 0px 20px 0px rgba(19, 25, 35, 0.1) !important;
  backdrop-filter: blur(25px) !important;
  margin-top: 72px; // Same as the header height
  height: calc(100vh - 72px) !important;
  overflow: hidden;
  z-index: 1001 !important;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.thinkingAnimation {
  margin: 0;
  padding: 0;
}

.chatWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: color(copilotLightColor) !important;
  backdrop-filter: blur(25px) !important;

  .chatError {
    @include textStyle(14px, 400);
    color: color(copilotErrorColor);
    padding: 0 20px;
    margin: 0;
  }

  .chatHeader {
    padding: 16px 16px 8px 16px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerText {
      @include textStyle(20px, 600);
      color: color(black);
    }

    .chatHeaderIcons {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        cursor: pointer;
      }
    }
  }
}

.MessageList {
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 8px;
  }
}

.MessageElement {
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  margin-top: 16px;
  p {
    color: color(black);
    @include textStyle(14px, 400);
  }
}

.ActionMenu {
  span {
    color: color(disabledText);
    @include textStyle(12px, 400);
  }

  ul {
    box-shadow: none;

    li {
      height: 38px;
      padding: 8px;
      border-radius: 4px;
      color: color(black);
      @include textStyle(14px, 400);
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      &:hover {
        background-color: color(copilotPrimaryColor);
        cursor: pointer;
      }
    }
  }
}

.MessageElementForUser {
  background-color: color(copilotUserColor);
}

.MessageElementForBot {
  background-color: color(lightYellow2);
}

.InteractionIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .actionButtons {
    display: flex;
    align-items: center;
    gap: 8px;

    .likeButton {
      path {
        fill: color(darkGreen2);
      }
    }

    .dislikeButton {
      path {
        fill: color(red);
      }
    }
  }

  svg {
    box-sizing: content-box;
    padding: 4px;
    transition: all 0.2s ease-in-out;
  }
  svg:hover {
    cursor: pointer;
    border-radius: 50px;
    background: color(copilotIconBg);
  }
}

.MessageInputContainer {
  left: 0;
  width: 100%;
  padding: 0 16px;
  margin-top: 10px;

  input {
    width: 100%;
    height: 48px;
    border-radius: 50px;
    background: color(blueTinge);
    padding: 0 16px;
    border: none;
    outline: none;

    color: color(disabledText);
    @include textStyle(16px, 400);
  }
}

.OptionMenu {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 2px solid color(copilotIconBg);
    padding: 24px;

    h1 {
      @include textStyle(18px, 500);
      color: color(black);
      margin-bottom: 12px;
    }

    li {
      color: color(black);
      border-radius: 50px;
      background: color(lightBlue2);
      padding: 8px 16px;
      margin-bottom: 8px;
      cursor: pointer;
      @include textStyle(14px, 400);
    }
  }
}

.chatContainer {
  height: 100%;
  max-height: 100%;
  padding-bottom: 145px;
  overflow-y: scroll;
  @include hideScrollbar();
}
