@import '../../../styles/scss/abstracts/variables.module';

.genericStagePopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    @include textStyle(16, 850);
  }

  svg {
    color: color(manatee);
    cursor: pointer;

    &:hover {
      color: color(black);
    }
  }
}

.genericStagePopupNavigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    gap: 8px;
    display: inline-flex;
    flex-direction: column;
    li {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: color(black);
      height: 38px;
      padding: 8px;
      border-radius: 6px;
      @include textStyle(14, 500);

      svg {
        color: color(black);
      }

      span {
        span {
          color: color(manatee);
          margin-left: 3px;
        }
      }
    }

    li:hover {
      background-color: transparent;
    }

    li.defaultNavigation {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }

    li.hideNavigation {
      opacity: 0;
      cursor: not-allowed;
      pointer-events: none;
    }

    li.activeNavigation {
      border: 1px solid color(gainsboro2);
      background-color: color(grayBg2);
    }

    li.complatedNavigation {
      border: 1px solid color(gainsboro2);
    }
  }
}

.logoutButton {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  @include textStyle(14, 500);

  svg {
    color: color(black);
  }

  &:hover {
    svg {
      color: color(midBlue);
    }

    span {
      color: color(midBlue);
    }
  }
}
