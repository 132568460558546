@import '../../../../../styles/scss/abstracts/variables.module';

.companyDetailsStage {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  padding-top: 10px;
  margin-bottom: 50px;

  .questionBox {
    width: 100%;
  }

  .answerBoxContainer {
    width: 100%;

    @keyframes blinkEffect {
      0% {
        outline: 2px solid color(midBlue);
      }
      50% {
        outline: 2px solid transparent;
      }
      100% {
        outline: 2px solid color(midBlue);
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border-radius: 8px;
      padding: 10px;
      @include textStyle(14px, 400);
      background-color: color(white);
      border: 2px solid transparent;

      &:disabled {
        outline: none;
        animation: blinkEffect 1.5s infinite;
        background-color: color(lightGrey);
      }
    }

    .answerBoxCounter {
      @include textStyle(14px, 400);
      color: color(black);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }

  .actionButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: -10px;

    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(124, 139, 255, 0.7);
      }
      70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 15px rgba(124, 139, 255, 0);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(124, 139, 255, 0);
      }
    }

    .recordButton {
      @include textStyle(14px, 500);
      border-radius: 50%;
      padding: 14px;
      border: 1px solid color(gainsboro2);
      color: color(black);
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease;

      &:hover {
        background-color: color(midBlue);
        color: color(white);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
        border-color: transparent;
      }

      &.activeRecord {
        background-color: color(midBlue);
        color: color(white);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
        border-color: transparent;
        animation: pulse 2s infinite;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .aiButton {
      @include textStyle(16px, 500);
      border-radius: 8px;
      padding: 10px 14px;
      min-width: 142px;
      min-height: 45px;
      color: color(white);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      background-color: color(midBlue);
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
        transform: scale(1.05);
      }

      &:active {
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
        transform: scale(0.95);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.questionBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  cursor: pointer;

  span {
    @include textStyle(14px, 700);
  }
}

.errorState {
  color: color(red) !important;
}

.redCircle {
  color: color(red);
}

.greenCircle {
  color: color(shamrockGreen);
}

.successState {
  outline-color: color(shamrockGreen);
  border-color: color(shamrockGreen) !important;
}

.errorState {
  outline-color: color(red);
  border-color: color(red) !important;
}

.companyDetailsStageTitle {
  @include textStyle(22px, 600);
  color: color(black);
  margin: 10px 0;
}
