@import '../styles/scss/abstracts/variables.module';
@import '../styles/scss/abstracts/mixins';

.cm-activeLine {
  background: transparent !important;
}

.cm-line {
  padding: 8px 0 !important;
  font-size: 14px;
}

.cm-line > span {
  display: inline-flex;
  color: color(black);
}

/* Styles for the custom tooltip container */
.myTooltip {
  background-color: color(white) !important;
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
  border-radius: 16px !important;
  max-width: 400px !important;
  width: 400px !important;
  max-height: 270px !important;
  padding: 12px 14px 12px 14px;
  overflow: auto !important;
  overflow-x: hidden !important;
  @include textStyle(14, 400);
}

.cm-completionIcon {
  display: none !important;
}

/* Styles for list items within the tooltip */
.myTooltip li {
  list-style-type: none !important;
  color: color(black) !important;
  line-height: 1.6 !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  cursor: default !important;
  padding: 0px 16px 0px 30px !important;
  height: 50px !important;
  position: relative !important;

  /* Add other styles as needed */
}

.myTooltip li .tooltip-icons {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.myTooltip li .tooltip-right-content {
  position: absolute;
  right: 10px;
  top: 15%;
  transform: translateY(-15%);
  max-width: 100px;
  flex: grow;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: color(grey);
}

.myTooltip li .tooltip-icons svg {
  margin-right: 8px;
  font-size: 16px;
}

.myTooltip li:hover {
  background-color: color(bgHoverList) !important;
  border-radius: 8px;
}

.myTooltip ul {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

/* Styles for the selected item within the tooltip */
.myTooltip li[aria-selected='true'] {
  background-color: color(bgHoverList) !important;
  border-radius: 8px;
}

.cm-completionDetail {
  padding: 0 !important;
  margin: 0 !important;
  color: color(darkgrayText) !important;
  @include textStyle(12, 400);
}

/* Styles the autocomplete tooltip container */
.cm-tooltip-autocomplete {
  @include textStyle(14, 400);
  background-color: color(white); /* Light grey background */
  border-radius: 0px; /* Rounded corners for the tooltip */
  overflow: hidden; /* Ensures the inner part does not bleed outside the border-radius */
}
/* Styles each autocomplete list item */
.cm-tooltip-autocomplete li {
  list-style: none; /* Removes list item bullets */
  padding: 0px 16px 0px 16px; /* Padding inside each list item */
  color: color(black); /* Darker text for better readability */
  line-height: 1.4; /* Slightly more line-height for readability */
  display: flex; /* Enables flexbox for aligning icon and text */
  align-items: center; /* Center items vertically in the list item */
}
/* Styles for the selected autocomplete item */
.cm-tooltip-autocomplete li[aria-selected='true'] {
  background-color: color(
    red
  ); /* Slightly darker background for the selected item */
}
/* Styles for the completion text */
.cm-completionLabel {
  color: color(black); /* Dark text color */
  @include textStyle(16, 400);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Styles for the matched text within the completion label */
.cm-completionMatchedText {
  font-weight: bold; /* Makes the matched text bold */
}

/* Styles for the completion icon */
.cm-completionIcon {
  /* Adjust width, height, and margin as needed */
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Customizing the background and text color */
.CodeMirror-hints {
  background-color: color(white);
  color: color(black);
}
/* Customizing individual hint */
.CodeMirror-hint {
  padding: 5px;
  font-size: 14px;
}
/* Styling for the selected hint */
.CodeMirror-hint-active {
  background-color: color(viridian);
  color: color(white);
}

.cm-gutters {
  display: none !important;
  min-height: 48px !important;
}

.react-aria-Button {
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.react-aria-Select,
.react-aria-SelectValue {
  font-family: sans-serif;
}

.react-aria-SelectValue[data-placeholder] {
  font-style: unset !important;
}

.react-aria-Select[data-placeholder] {
  font-style: unset !important;
}

.react-aria-Select {
  margin-right: 0.15em;
  padding: 2px 7px;
  border-radius: 0px;
  width: auto;
  position: relative;
}

.react-aria-Popover {
  width: 250px;
  padding: 0;
}

.react-aria-ListBox {
  padding: 0;
}

.react-aria-ListBoxItem {
  padding: 0.5em 1em;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  outline: none;
}

.react-aria-ListBoxItem:hover {
  background: color(white);
}

.cm-editor {
  box-sizing: border-box;
  padding: 0px 24px;
  border: 2px solid color(formulaColor) !important;
  box-shadow: 0px 0px 5px 5px color(iconHoverColor);
  min-height: 48px;
  border-radius: 8px;
}

#rowEditor .cm-scroller {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

#rowEditor .cm-editor {
  border-color: transparent !important;
  max-height: 48px;
  width: 100vw !important;
  text-align: left;
  vertical-align: middle;
  border-radius: 0px;
  height: 100%;
}

#rowEditor .cm-content {
  padding: 0;
  display: flex !important;
  align-items: center;
}

#rowEditor .cm-line {
  padding: 0 !important;
}

#rowEditor .cm-cursor {
  display: none;
}

.ͼ1 .cm-tooltip.cm-tooltip-autocomplete > ul {
  max-height: unset;
  overflow: initial;
  display: flex;
  flex-direction: column;
}

.ͼ1 .cm-completionListIncompleteTop:before,
.ͼ1 .cm-completionListIncompleteBottom:after {
  display: none;
}

.ͼ1 .cm-tooltip.cm-tooltip-autocomplete > ul > li {
  overflow-x: initial;
}

.cm-tooltip-autocomplete,
.myTooltip,
.cm-tooltip,
.cm-tooltip-below {
  height: auto !important;
}

.cm-custom-main {
  display: flex;
  flex-wrap: wrap !important;
}

.cm-description {
  width: 100%;
}
