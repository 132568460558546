@import '../../../styles/scss/abstracts/variables.module';

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  @apply bg-white rounded-lg;
}

.menuItem {
  @apply flex items-center cursor-pointer rounded w-full transition duration-300 ease-in-out px-3 py-1;
}

.menuItem:hover {
  background-color: #f0f0f0; /* Change as needed */
}

.icon {
  @apply mr-2;
}

.label {
  @apply text-sm text-black-100;
}

.disabled {
  @apply cursor-not-allowed;
}

.disabled span {
  color: color(disabledText);
}
