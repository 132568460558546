@import '../../../../../styles/scss/abstracts/variables.module';

.folderCalculationContainer {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  .buttonStyle {
    width: auto;
    padding: 0 10px;
    height: 25px;
    width: 40px;
    background-color: color(iconHoverColor);
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;

    .avgIcon {
      @include textStyle(14px, 400);
    }
  }

  .activeButton {
    background-color: color(midBlue);
    color: color(white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;

    .avgIcon {
      color: color(white);
    }
  }

  &:hover {
    .buttonStyle {
      display: flex !important;
    }
  }
}
