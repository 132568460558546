@import '../styles/scss/abstracts/variables.module';

.mainSection {
  @apply flex overflow-auto px-6 pt-5 pb-3;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 1rem;
}

.mainSection::-webkit-scrollbar {
  display: none;
}

/* `h-[ relative  flex ${theme.hiringPage.containerHeight}] w-[${theme.hiringPage.containerHeight}] shrink-0 flex-col bg-white p-6 text-gray-900 shadow-xl` */

.employeeChartContainer {
  @apply flex relative shrink-0 flex-col p-6 pt-0;
  height: auto;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-width: 600px;
  max-height: 400px;
  border-radius: 24px;
  overflow: hidden;
  background-color: color(white);
}

.employeeChartDrowpDown {
  @apply mb-1 flex items-center justify-between;
  height: 10%;
}

.employeeChartDrowpDown span {
  @apply flex items-center space-x-2 text-xl focus:outline-none;
  font-family: 'Figtree';
  font-size: '22px';
  font-weight: '500';
}

.employeeChartStats {
  @apply mb-6 flex items-center justify-between;
}
.employeeChartStats h3 {
  @apply text-xs font-medium text-gray-700;
}

.employeeChartStats span {
  @apply text-xs text-gray-700;
}

.donutChartContainer {
  @apply relative flex shrink-0 items-center bg-white text-gray-900;
  padding: 12px 24px 24px 24px;
  border-radius: 0 0 24px 24px;
  height: 340px;
  width: 570px;
  overflow: hidden;
}

.datePickerText {
  @include textStyle(25px, 500);
  color: color(black);
}

.donutLabelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  margin-left: -40px;
}

.donutChartTab {
  @apply w-full;
}

.donutChartTabList {
  @apply flex;
}

.donutChartTabPanel {
  @apply flex flex-col items-center overflow-hidden p-6;
}

.donutChartTabPanel div {
  @apply mb-4 w-64 px-2;
}

.chartPopupDropdown {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply rounded bg-white;
  padding: 0.5rem;
  cursor: pointer;
}

.chartPopupDropdown:hover {
  @apply bg-gray-200;
}

.chartPopupDropdownText {
  cursor: pointer;
}

.chartPopupDropdownText img {
  transform: rotate(90deg);
  margin-left: 0.5rem;
}

.donutTab ul {
  @apply bg-white font-medium;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  padding: 20px 30px 0 30px;
  border-radius: 24px 24px 0 0;
}

.donutTab ul li {
  display: inline-block;
  display: flex;
  justify-content: center;
  padding: 0px;
  padding-bottom: 1rem;
  outline: none;
  box-shadow: none;
  z-index: 999;
  margin-right: 20px;
  background: transparent;
}

.donutTab ul li:hover {
  @apply text-black-100;
}

.tabSelected {
  @apply text-black-100;
  border-bottom: 4px solid #5463ff;
}

.datePickerForDonut {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0.5rem;
  padding-bottom: 10px;
  width: 100%;
  z-index: 100;
  position: relative;
}

.datePickerForDonut .leftArrow {
  transform: rotate(180deg);
}

.datePickerForDonut img {
  cursor: pointer;
  margin: 0 0.5rem;
}

.datePickerForDonut .dateText {
  cursor: pointer;
}

.chartTab {
  box-sizing: border-box;
  @apply flex items-center;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-top: 20px;
}

.chartTab button {
  background: transparent;
  /* z-index: 999;  */ /* Doesn't need to have this above all elements */
  padding: 5px 10px 1rem 10px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  margin-right: 20px;
  border-bottom: 4px solid;
  border-color: transparent;
  z-index: 100;
  position: relative;
  @include textStyle(16px, 500);
  color: color(black);
}

.chartTabSelected {
  @apply text-black-100;
  border-bottom: 4px solid #5463ff !important;
}

.shineAnimation {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
  border-radius: 24px;
  overflow: hidden;
  /* @apply shadow-xl; */
}

.noAnimation {
  width: 100%;
  position: absolute;
  z-index: -1;
  /* @apply shadow-xl; */
}

.shineAnimationDonut {
  width: 100%;
  height: auto;
  position: absolute;
  display: block !important;
  z-index: 1;
  border-radius: 24px;
  overflow: hidden;
}

.animationPlaceholder {
  width: 100%;
  height: 400px;
  display: flex;
  visibility: hidden;
  border-radius: 24px;
  overflow: hidden;
}

.shineAnimationDonut .donutChartContainer {
  height: 100%;
}

.donutTab {
  height: 100%;
}

.shineAnimationDonut .donutTab {
  height: 100%;
  background: #fff;
}

.donutTabPanel {
  background-color: color(white);
  border-radius: 0 0 24px 24px;
}

.shineAnimationDonut .donutTab .tabPanel {
}

.disabledArrow {
  opacity: 0.5;
}

.disabledArrow img {
  cursor: not-allowed;
}
