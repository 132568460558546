@import '../../../styles/scss/abstracts/_mixins.scss';
@import '../../../styles/scss/helper.scss';
@import '../../../styles/scss/abstracts/variables.module';

@mixin border {
  @apply border-b;
  border-color: color(lightGrey5);
}
.textXs {
  font-size: 0.65rem;
}
.gridItem {
  @apply flex items-center justify-between;
}
.modalWidth {
  @apply w-[1120px] h-[770px] rounded-2xl px-6 pt-2 pb-4;
}
.popupTitle {
  @apply py-2 flex items-center gap-2;
  @extend .fs-28-500;
}
.mainWrapper {
  @apply relative transition-all duration-200 ease-in-out flex gap-2 h-[630px];
}

.formWrapper {
  width: 100%;
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mainWrapper > :first-child {
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  // @include hideScrollbar;
}
.formBorder {
  @apply my-3;
  @include border;
}
.tabTitle {
  @apply mb-3;
  @extend .fs-14-400;
}
.tabSection {
  .tabContainer {
    @apply flex items-center gap-4;
    > button {
      outline: none; // Prevent adding dark outline on open popup
    }
    .tabButtonSelected {
      @apply min-w-[186px] min-h-[40px] rounded-lg border;
      @include fS-14-500;
      border-color: color(midBlue);
      color: color(midBlue);
      background-color: color(lightBlue2);
      // display: flex !important;
      justify-content: center;
    }
    .tabButtonNotSelected {
      @apply min-w-[185px] min-h-[40px] rounded-lg;
      @include fS-14-500;
      background-color: color(lightGrey);
      color: color(grey);
      //  display: flex !important;
      justify-content: center;
    }
    .tabText {
      @apply py-1 px-4;
    }
  }
}
.groupWrapper {
  @apply flex gap-1 items-center;
  .groupTitle {
    @include fS-14-500;
    color: color(midBlue);
  }
}
.buttonDisabled {
  @apply w-full bg-gray-200 rounded-sm py-2;
}
.graphSelect {
  @apply w-full;
  .selectButton {
    @apply flex w-full items-center justify-between space-x-2 rounded-lg border border-gray-300 px-4 hover:border-gray-500 focus:outline-none;
    .selectValue {
      @apply flex items-center gap-2 my-2;
      img {
        @apply h-[24px] w-[24px];
      }
      span {
        @include fS-14-500;
      }
    }
  }
}
.forecastTitle {
  @extend .fs-14-400;
}

.cancelButton {
  @apply px-4;
  @extend .fs-16-400;
}
.confirmButton {
  @apply px-4 py-2 rounded-lg;
  background-color: color(midBlue);
  span {
    color: color(white);
    @extend .fs-16-400;
  }
}

.itemContainer {
  @apply m-2 rounded border border-blue-600 px-4 py-3 hover:border-gray-500;
  .button {
    @apply flex w-full items-center justify-between space-x-2 focus:outline-none;
    .container {
      @apply flex items-center space-x-2;
      .containerInner {
        @apply flex h-12 w-12 items-center justify-center bg-gray-100 text-gray-500 group-hover:text-gray-600;
      }
      .label {
        @apply text-xs text-gray-900;
      }
    }
  }
}
.wrapper {
  @apply w-[578px] h-[80px];
  .wrapperInner {
    @apply flex w-full overflow-x-auto;
    @include hideScrollbar;

    &:hover {
      -ms-overflow-style: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 4px;
        display: block;
      }
      &::-webkit-scrollbar-track {
        display: block;
      }
    }

    .wrapperMain {
      @apply me-2 whitespace-nowrap;
      .title {
        color: color(grey);
        @extend .fs-14-600;
      }
      .info {
        color: color(black);
        @extend .fs-32-600;
      }
    }
  }
}
.group {
  @apply flex w-full h-full;
}
.footer {
  @apply flex justify-end mt-2;
}
.chartParent {
  @apply p-[23px] w-[643px] flex flex-col;
}
.chartTitle {
  @apply py-2;
  @include fS-14-500;
}

.graphTypeWrapper {
  height: 350px;
  width: 100%;
  max-width: 385px;
  background-color: color(white);
  overflow: auto;
  max-height: 350px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid var(--spectrum-global-color-gray-400);

  @include hideScrollbar;
}
