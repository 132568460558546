@import '../../../styles/scss/abstracts/variables.module';

.threeDotsAnimationWrapper {
  padding: 8px;
  margin: 0;
  flex: 1;
  min-width: 160px;

  span {
    @include textStyle(14px, 400);
    color: color(darkestGrey2);
  }

  .dot {
    display: inline-block;
    margin-left: 4px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: color(darkestGrey2);
  }
}
