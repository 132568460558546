@import '../../../../../styles/scss/abstracts/variables.module';

.drawerAISuggestion {
  background-color: color(whisperBlue);
  margin: 10px;
  border-radius: 16px;
  padding: 16px 20px;

  .drawerAISuggestionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .aiResponseContainer {
      height: 220px;
      max-height: 220px;
      overflow-y: auto;
    }

    div {
      display: flex;
      width: 100%;

      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        align-self: flex-start;
        max-width: max-content;
        border-radius: 50%;
      }

      p {
        @include textStyle(14px, 400);
        color: color(manatee);
        margin: 0;
      }
    }

    .aiResponse {
      background-color: transparent;
      p {
        @include textStyle(14px, 400);
        color: color(manatee);
        margin: 0;
      }
    }

    .seperator {
      width: 100%;
      height: 1px;
      background-color: color(lightGrey3);
      margin: 10px 0;
    }

    .transactionsInfo {
      display: flex;

      .transactionsTotal {
        @include textStyle(14px, 700);
        color: color(black);
      }

      .transactionsForcast {
        display: flex;
        flex-direction: column;
        @include textStyle(12px, 400);
        color: color(carbon400);

        span {
          @include textStyle(16px, 500);
        }
      }

      .transactionsActual {
        display: flex;
        flex-direction: column;
        @include textStyle(12px, 400);
        color: color(carbon400);
        span {
          color: color(black);
          @include textStyle(16px, 500);
        }
      }
    }
  }
}
