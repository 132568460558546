@import '../../../../../styles/scss/abstracts/variables.module';

.newCompanySetupStage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .newCompanySetupStageContent {
    @apply relative flex h-full w-full flex-col items-center justify-center;
  }
}

.welcomeMessage {
  @include textStyle(22px, 500);
  color: color(black);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 15%;

  h1 {
    margin-top: 15px;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: -40px;
  }
}

.companyPlans {
  margin-top: 100px;
  display: flex;
  gap: 20px;

  .companyPlan {
    width: 220px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    border: 0.5px solid color(manatee);
    border-top: 3px solid color(midBlue);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: white;

    .bottomBadge {
      background-color: color(black);
      color: color(white);
      border-radius: 3px;
      @include textStyle(14px, 500);
      padding: 2px 4px 3px 4px;
    }

    svg {
      color: color(midBlue);
    }

    h1 {
      @include textStyle(18px, 600);
      color: color(midBlue);
      transition: all 0.3s ease;
      line-height: 1.2;

      span {
        @include textStyle(14px, 400);
      }
    }

    p {
      @include textStyle(16px, 400);
      color: color(black);
      transition: all 0.3s ease;
    }

    &:hover {
      @apply shadow-md;
      transform: scale(1.05);
      border-color: color(midBlue);
    }

    &.selected {
      background-color: color(midBlue);
      border-color: color(midBlue);
      @apply shadow-md;

      &:hover {
        transform: none;
      }

      h1 {
        color: color(white);
      }

      p {
        color: color(white);
      }

      svg {
        color: color(white);
      }
    }
  }
}

.shineBorder {
}
