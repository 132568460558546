@import '../../../styles/scss/abstracts/variables.module';

.pageTitleContainer {
  margin: 23px 0 0px 0;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.pageTitle {
  width: max-content;
  height: max-content;
  display: flex;
  white-space: nowrap;
  align-items: center;
  @include textStyle(32px, 500);
  color: color(black);
}

.pageBg {
  background-color: color(blueTinge);
}

.aiContainer {
  position: sticky;
  top: 70px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
}

.aiContainer > * {
  pointer-events: auto;
}

.helpButtonContainer {
  width: auto;
  max-width: 300px;
  height: auto;
  position: relative;

  .helpButton {
    svg {
      color: color(black);
    }
  }

  .helpButton:hover ~ .helpButtonWidgetsContainer,
  .helpButtonWidgetsContainer:hover {
    opacity: 1;
    pointer-events: auto;
  }

  .helpButtonWidgetsContainer {
    position: absolute;
    top: 23px;
    left: 0;
    z-index: 1000;
    opacity: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .elfsightWidget {
    width: 300px;
    @apply shadow-md;
  }
}
