@import '../scss/abstracts/variables.module';

.formula-cell-wrapper {
  //margin: 0.5em;
  box-shadow: 0px 0px 5px 0px color(iconHoverColor);
  border: 1.5px solid color(formulaColor) !important;
  width: 100%;
  border-radius: 8px;

  * {
    box-sizing: border-box;
    position: unset;
  }

  main {
    flex: 1;
    background: color(white);
    width: 100%;
  }

  h1 {
    margin-bottom: 50px;
  }

  #lang {
    margin-bottom: 25px;
  }

  .menu-container {
    width: 100%;
    min-width: 600px;
  }

  .left-menu {
    display: inline-flex;
    gap: 20px;
  }

  .right-menu {
    float: right;
  }

  .right-menu div {
    display: inline;
  }

  a:link,
  a:visited {
    background-color: color(cobaltBlue);
    color: color(white);
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  a:hover,
  a:active {
    background-color: rgb(30, 26, 233);
  }

  .editor {
    min-height: 48px;
    max-height: calc(100vh - 40px - 30vh);
  }
}

.tag {
  margin-right: 0.15em;
  padding: 2px 7px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  @include textStyle(12px, 400);
}

.textFont {
  @include textStyle(12px, 500);
  color: color(black);
}

.widgetWrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-aria-SelectValue,
.react-aria-Select {
  border: 0;
  box-shadow: none;
  font-style: normal;
  font-weight: normal;
}

.formulaCellButton {
  height: cellConfig(cellHeight);
  max-height: cellConfig(cellHeight);
  width: 220px;
  display: flex;
  min-width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: color(lightBlue4);
  }
}

.rowEditor {
  width: 100%;
  height: cellConfig(cellHeight);
  // block accsess to click on the cell
  pointer-events: none;
}

.visible {
  display: flex !important;
}

.notVisible {
  display: none !important;
}

.reposition {
  right: 0;
  left: unset;
}

.helper-variables {
  padding: 0.5em 1em;
  @include textStyle(14px, 500);
  display: flex;
}

.modal {
  display: flex;
  position: fixed !important;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background: color(mistyBlue);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: center;

  .container {
    background: color(white);
    padding: 2em;
    border-radius: 1em;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        @include textStyle(24px, 600);
      }
      button {
        @include textStyle(24px, 600);
        background: color(wintermist);
        padding: 0 0.5em;
        border-radius: 0.25em;
        color: color(mediumGray3);
        &:hover {
          color: color(black);
        }
      }
    }
    .largeTextarea {
      textarea {
        width: 100%;
        border: 2px solid color(oceandusk);
        min-height: 7em;
        padding: 1em;
        border-radius: 0.5em;
        margin: 1em 0;
        cursor: text !important;
      }
    }
    .textElements {
      display: flex;
      flex-direction: column;
      gap: 2em;
      .shared {
        padding: 1em;
        background: color(eggshell);
        border-radius: 0.5em;
        h4 {
          font-weight: 600;
        }
      }
    }
  }
}

.variable {
  color: color(white) !important;
  border: 1px solid color(midBlue) !important;
  background-color: color(midBlue) !important;
  margin-right: -15px;
}

.customTag {
  margin-right: 0 !important;
}

.formula {
  color: color(white) !important;
  border: 1px solid color(turquoise) !important;
  background-color: color(turquoise) !important;
}

.emptyFormula {
  color: color(white) !important;
  border: 1px solid color(mediumGray) !important;
  background-color: color(mediumGray) !important;
}

.function {
  color: color(white) !important;
  border: 1px solid color(seafoam) !important;
  background-color: color(seafoam) !important;
}

.select-arrow {
  height: 0.7em;
  margin-left: 1em;
}

.select {
  display: flex;
  align-items: center;
  padding: 0.15em 0.5em;
  padding-left: 20px;
  border-radius: 8px;
  color: color(slateGray);
  background-color: color(ghostWhite);
  border: 1px solid color(ghostWhite);
  @include textStyle(14px, 400);
}

.listBoxItem {
  @include textStyle(14px, 500);
  color: color(black);
  padding: 5px 10px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: color(ghostWhite);
    color: color(black);
  }
}

.dropdownWidget {
  display: inline-flex;
  align-items: center;
}

.formulaCountCell {
  height: cellConfig(cellHeight);
  max-height: cellConfig(cellHeight);
  width: 220px;
  display: flex;
  min-width: 220px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  align-items: center;
  overflow: hidden;
  color: color(midBlue);
  @include textStyle(14px, 400);

  &:hover {
    background-color: color(lightestGrey2);
  }
}

.formulaViewCell {
  width: cellConfig(cellWidth);
  height: cellConfig(cellHeight);
  max-width: cellConfig(cellWidth);
  max-height: cellConfig(cellHeight);
  display: flex;
  align-items: center;
  position: relative;
  @include textStyle(14px, 400);

  &:hover {
    background-color: color(lightBlue4);
  }

  svg {
    margin-left: 24px;
    position: absolute;
  }

  .rightArrow {
    width: 30px;
    height: 48px;
    position: absolute;
    cursor: pointer;
    left: -3px;
    top: 0;
    border-left: 3px solid color(midBlue);
    display: flex;
    align-items: center;
    z-index: 1;
    transition: visibility 0.3s;

    svg {
      margin-left: -3px;
      visibility: hidden;
      transition: visibility 0.3s;
      color: color(gray);

      &:hover {
        color: color(midBlue);
      }
    }

    &:hover {
      svg {
        visibility: visible;
      }
    }
  }

  .leftArrow {
    width: 30px;
    cursor: pointer;
    height: cellConfig(cellHeight);
    position: absolute;
    right: -30px;
    top: 0;
    border-left: 3px solid color(midBlue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    transition: visibility 0.3s;

    svg {
      margin-left: -3px;
      visibility: hidden;
      transition: visibility 0.3s;
      color: color(gray);

      &:hover {
        color: color(midBlue);
      }
    }

    &:hover {
      svg {
        visibility: visible;
      }
    }
  }
}
