@import 'scss/abstracts/variables.module';

.modalContainer {
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  h3 {
    color: #141a23;
    font-family: Figtree;
    font-size: 20px;
    font-weight: 400;
  }
}

.modalCancelBtn {
  color: #141a23;
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  width: fit-content !important;
  display: inline-flex;
}
.modalSubmitBtn {
  width: 150px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #7785f3;
  color: #fff;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
}

.modalInput {
  padding: 1.3rem 1rem;
  width: 100%;
  border: 1px solid color(lightestGrey);
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  @include textStyle(14px, 400);
  color: color(black);
}

.modalSelect {
  padding: 0.7rem;
  width: 100%;
  border: 1px solid color(lightestGrey);
  border-radius: 8px;
  margin-bottom: 10px;
  @include textStyle(14px, 400);
  color: color(black);
  appearance: none;
  cursor: pointer;
  background-color: color(white);
}

.modalUrlInput {
  @apply rounded-md;
  border: 1px solid color(lightestGrey);
  display: flex;
  align-items: center;
  padding: 0.5rem;

  div {
    @include textStyle(12px, 400);
    color: color(black);
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    @include textStyle(14px, 400);
    color: color(black);
  }
}

.modalLabel {
  color: color(black);
  @include textStyle(14px, 400);
  display: inline-block;
  @apply ml-1 mb-1;
}

.footerBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @apply pt-4;
}

.dangerZoneAlert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid color(alertBorderRed);
  border-radius: 8px;
  padding: 20px 10px;
  background-color: color(alertBgRed);
  @include textStyle(14px, 400);
  @apply mt-4;
}

.dangerZoneBtn {
  border: 1px solid color(midRed);
  padding: 12px 14px;
  border-radius: 8px;
  color: color(midRed);
  cursor: pointer;
  @include textStyle(14px, 400);
}

.disabledBtn {
  border: 1px solid color(lightGrey3);
  color: color(grey);
  cursor: not-allowed;
}

.dangerZoneModal {
  @apply px-6 py-4;
  max-width: 500px;

  .dangerZoneBtn {
    margin-left: 20px;
  }

  .dangerZoneModalContent {
    @include textStyle(16px, 500);
    color: color(black);
    margin-bottom: 20px;
  }
}
