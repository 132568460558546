@import '../../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../../styles/scss/abstracts/mixins';

.departmentHeaderCell {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    width: 1px;
    background-color: color(lavendeGray);
  }
}

.tableHeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.stickyColumn {
  position: sticky !important;
  left: -1px;
  z-index: 100;
  background-color: white;
  border-right-width: 1px !important;
  min-width: cellConfig(cellWidth);
  max-width: cellConfig(cellWidth);
  width: cellConfig(cellWidth);
}

.topLeftCell {
  @extend .stickyColumn;
  font-size: 22px !important;
  padding: 0 24px !important;
  border-right-width: 1px !important;
  z-index: 2;
  height: 100%;
}

.lastHeader {
  width: 100%;
}

.topHeader {
  background-color: color(white);
  position: relative;
  width: 100%;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    background: repeating-linear-gradient(
      -45deg,
      color(midBlue),
      color(midBlue) 2.5px,
      color(white) 2.5px,
      color(white) 16px
    );
  }
}

.stickyHeader {
  position: sticky;
  top: 0;
  font-size: 16px !important;
  z-index: 1; /* Make sure the header is above other elements */
  border: 1px solid color(lavendeGray);
  height: 56px;
  background-color: color(white);
}

.tableSettingsHeaderNavigationButtons {
  display: flex;
  justify-content: flex-end;

  & > button {
    padding: 6px;
    &:disabled {
      cursor: auto;
      & img {
        filter: invert(99%) sepia(3%) saturate(1665%) hue-rotate(242deg)
          brightness(150%) contrast(75%);
      }
    }
  }

  & img {
    margin: 0;
  }
}

.leftArrow {
  rotate: 180deg;
}
