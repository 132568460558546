@import '../../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../../styles/scss/abstracts/mixins';
@import './tableSidebarStyles.module.scss';

.cellBorder {
  border: 1px solid;
  border-color: color(lavendeGray);
}

.baseFont {
  color: color(black);
  font-family: 'Figtree';
}

#customTable {
  * {
    font-family: 'Figtree';
    outline: none;
  }

  border-collapse: collapse;
  width: 100%;
  background-color: color(white);
  border-width: 1px;
  border-color: color(lavendeGray);

  th {
    text-align: left;
    font-weight: 500;
  }

  input {
    @include remove-number-input-spinners;

    border: 0;
    outline: 0;
    height: 100%;
    display: flex;
    position: relative;
    background-color: transparent;

    z-index: 1;
    &:hover {
      @apply bg-tableSidebarHoverBg;
      @apply border-tableInputFocus;
    }
    &:focus {
      border: 1px solid;
      @apply border-tableInputFocus;
    }
  }

  td {
    &:first-child {
      > ::after {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        width: 100px;
        right: -100px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent);
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  td {
    @extend .cellBorder;
    text-align: left;
    padding: 0;
    max-height: cellConfig(cellHeight);
    max-width: cellConfig(sidebarCellWidth);
  }

  tr {
    font-weight: 500;
    padding: 0;
  }

  .stickyColumn {
    @extend .cellBorder;
    @extend .baseFont;
    position: sticky;
    left: -1px;
    z-index: 100;
    background-color: color(hite);
    border-right-width: 1px !important;
    min-width: cellConfig(sidebarCellWidth);
    max-width: cellConfig(sidebarCellWidth);
    width: cellConfig(sidebarCellWidth);
  }

  .topLeftCell {
    @extend .stickyColumn;
    font-size: 22px !important;
    padding: 0 24px !important;
    border-right-width: 1px !important;
    z-index: 2;
    height: 56px;
    max-height: 56px;
    width: cellConfig(sidebarCellWidth);
    max-width: cellConfig(sidebarCellWidth);
  }

  .departmentHeaderCell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      height: 100%;
      width: 1px;
      background-color: color(lavendeGray);
    }
  }

  .tableHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.tableHead {
  @extend .cellBorder;
  position: sticky;
  z-index: 3;
}

.baseCell {
  @extend .baseFont;
  font-weight: 400;
  height: 100%;
}

.currencyCellRight {
  position: relative;
  height: 100%;
  span {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 66;
    @include textStyle(14px, 400);
  }

  input {
    height: 100%;
  }
}

.tableWrapperContainer {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 24px;
    height: 90%;
    width: 1px;
    bottom: 0;
    background-color: color(white);
  }
}

.tableWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  @include hideScrollbar;
}

.hoverStylesDrawerCell {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: transparent;
    border: 1px solid color(midBlue);
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    &::after {
      opacity: 1;
    }
  }

  &:active {
    &::after {
      opacity: 1;
    }
  }
}

.viewCellContent {
  width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.baseCellsContainer {
  width: 100%;
  height: 100%;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: color(lightBlue4);
  }
}
