@mixin hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin add-left-border {
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 1px;
    background-color: color(lavendeGray);
  }
}

@mixin remove-number-input-spinners {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin displayFlex {
  display: flex;
}

@mixin positionRelative {
  position: relative;
}

@mixin positionAbsolute {
  position: absolute;
}

@mixin alignCenter {
  align-items: center;
}

@mixin justifySpaceBtw {
  justify-content: space-between;
}

@mixin justifyFlexEnd {
  justify-content: flex-end;
}

@mixin cursorPointer {
  cursor: pointer;
}

@mixin flexColumn {
  flex-direction: column;
}

@mixin textCenter {
  text-align: center;
}

@mixin flex-item-container {
  @include displayFlex;
  @include alignCenter;
  justify-content: center;
}

@mixin absCenter {
  @include positionAbsolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absLeft {
  @include positionAbsolute;
  top: 50%;
  transform: translate(0, -50%);
}

@mixin fontWeight($fontWeight) {
  font-weight: $fontWeight;
}

@mixin gradient($rotation) {
  background: linear-gradient(
    $rotation,
    #f7f8fb 33.76%,
    rgba(247, 248, 251, 0) 91.69%
  );
}
@mixin fS-14-500 {
  font-size: 14px;
  font-weight: 500;
}
@mixin fS-16-500 {
  font-size: 14px;
  font-weight: 500;
}
@mixin respond($breakpoint) {
  @if $breakpoint ==extra-large-desktop {
    @media only screen and (min-width: 1500px) {
      @content;
    }
  }

  @if $breakpoint ==large-desktop-min {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==large-desktop-max {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==small-desktop-min {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint ==small-desktop-max {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint ==large-desktop {
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      @content;
    }
  }

  @if $breakpoint ==small-desktop {
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint ==extra-large-mobile {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint ==small-to-large-mobile {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint ==large-mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint ==small-mobile {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }

  @if $breakpoint ==extra-small-mobile {
    @media only screen and (max-width: 479px) {
      @content;
    }
  }
}

@mixin flex($flex-flow, $justify-content, $align-item) {
  display: flex;
  flex-direction: $flex-flow;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
