@use 'sass:map';

/* Colors */
$primary-color: #3498db;
$secondary-color: #e74c3c;
$text-color: #333;
$background-color: #f0f0f0;
$white-color: #ffffff;
$stripeColor: #fafafa;
$cellBorderColor: #d4d4d4;
$grey-color: grey;
$blue-color: #3d7dff;
$overlay-grey: #d9d9d9;

/* Fonts */
$font-family: Figtree;
$font-family-bold: Figtree;
$font-size: 16px;
@mixin textStyle($size, $weight) {
  $font-family: Figtree !important;
  font-size: $size !important;
  font-weight: $weight !important;
}

// $font-family: “Figtree”;
// $font-family-bold: “Figtree”;
// $font-size: 16px;

/* Spacing */
$padding: 10px;
$footer-space: 20px;
/* Borders */
// botton
$border-button-radius: 5px;

/* Breakpoints */
$small-screen: 768px;
$medium-screen: 992px;
$large-screen: 1200px;

/* Cell Table */
/* Colors */
$cell-background-color: rgb(169, 169, 19);
$cell-text-color: #e74c3c;
$cell-border-color: grey;

/* Fonts */
$cell-font-family: Arial, sans-serif;
$cell-font-family-bold: Arial-Bold, sans-serif;
$cell-font-size: 16px;

/* Spacing */
$cell-padding: 5px;

/* Borders */
$cell-border-radius: 5px;
$cell-order-radius: 1px;
$cell-order-radiusr: #ccc;

$cell-width: 105px;
$cell-height: 40px;
$sidebar-cell-width: 340px;
$sidebar-additional-width-financials: 120px;
$sidebar-additional-width-models: 110px;
$sidebar-additional-width-hp: 130px;
$sidebar-cell-additional-ident: 15px;
$min-output-cell-width: 220px;
$output-cell-select-width: 140px;

/* Global Header */
$global-header-font-family: Figtree;
$global-header-color-darkBlue: #141a23;
$global-header-color-gray: #2c2c2c;
$global-header-color-darkestGrey: #333;
$global-header-color-lightestGray: #f7f7f7;
$global-header-color-white: #fff;
$global-header-color-black: #1a1a1a;
$global-header-color-border: #c2cbd9;
$global-header-color-deepNavy: #131923;
$global-header-color-blueTinge: #f7f8fb;
$loader-foreground-color: #dedede;
$slate-gray: #818181;

$subHeader-yellow: #ffba5e;
$subHeader-link-border-blue: #5463ff;
$light-blue: #b8c7ff;
$light-grey: #8c8c8c;
$border-grey: #c2cbd9;

$mid-blue: #7c8bff;
$select-border-color: #d1d5db;
$dusk-gray: #7c7f86;
$light-grey-2: #f7fafc;
$formula-option-shadow: #00000026;

// _colors.scss
$colors: (
  tableBorderColorRGB: rgb(214 214 214),
  disabledModeColor: rgb(194, 203, 217, 0.15),
  themeColor: #5096fd,
  grey: #9c9da6,
  // rgba(140, 140, 140, 1)
  lightGrey: #f7f7f7,
  blueline: #daeeff,
  // rgba(247, 247, 247, 1)
  copilotIconBg: #e1e1e1,
  // rgba(225, 225, 225, 1); light grey
  copilotUserColor: #f1f2fb,
  // rgba(241, 242, 251, 1); light blue
  darkBlue1: #141a23,
  // rgba(20, 26, 35, 1); dark blue
  copilotLightColor: #feffff,
  // rgba(254, 255, 255, 1); white
  copilotErrorColor: #e10815,
  // rgba(225, 8, 21, 1); red
  copilotPrimaryColor: #f1f5ff,
  black: #202024,
  // rgba(19, 25, 35, 1)
  tableBorderColor: #d6d6d6,
  // rgba(214, 214, 214, 1) grey
  tablePlusButtonText: #919daf,
  borderGrey3: #e7e7e9,
  borderGrey3Light: #f0f0f2,
  grayColor3: #838b96,
  tableInputBg: #daebff,
  disabledText: #787878,
  darkgrayText: #858585,
  iconHoverColor: #eeeeee,
  bgHoverList: #eaf3ff,
  skyBlue: #42c2ff,
  // sky blue
  midBlack: #2c2c2c,
  // mid black
  calendarWeekday: #293050,
  // calendar weekday color
  selectedDateBg: #0080ff,
  selectedMonthBg: #d9ecff,
  monthDisabledColor: rgba(24, 30, 48, 0.32),
  calendarHoverColor: #181e301f,
  // selected date background color
  darkBlack: #050505,
  // dark black
  darkBlack2: #00000026,
  //rgba(0, 0, 0, 0.15)
  white: #ffffff,
  // white
  lightwhite: #fffefe,
  // white
  lightwhite2: #fefffe,
  lightwhite3: #f5f5f5,
  // white
  pink: #ff15c4,
  // pink
  midPink: #f572d8,
  // rgba(245, 114, 216, 1), mid pink
  lightPink: #ffc7f1,
  // light pink
  lavender: #a675e2,
  // lavender
  lavender2: #d4d9e6,
  // lavender
  darkViolet: #a1a7f5,
  // lavender blue
  lightCyan: #9ad8d8,
  // rgb(154, 216, 216)
  lightViolet: #a4b3ff,
  // rgb(164, 179, 255)
  mediumGray: #aeaeae,
  lightGray2: #eef0f2,
  // light grey
  lightslategray: #8a8db4,
  // medium grey
  mediumGray2: #616161,
  // medium grey
  // mid grey
  lightGrey2: #f7fafc,
  // light grey
  lightGrey3: #d9d9d9,
  // light grey
  lightGrey4: #eef2ff,
  // light grey
  lightGrey6: #f5f5f5,
  lightGrey5: #c2cbd9,
  lightGrey7: #e6e6ed,
  // light grey rgba(194, 203, 217, 1)
  lightestGrey: #e6e6e6,
  lightestGrey2: #f9f9f9,
  // lightest grey
  darkGrey: #8c8c8c,
  // dark grey
  darkestGrey: #6b6b6b,
  // dark grey,
  darkestGrey2: #333333,
  // dark grey,
  darkestGrey3: #757575,
  // dark grey,
  yellow: #ff8717,
  // yellow
  yellow2: #ffba5e,
  // rgba(255, 186, 94, 1); yellow
  yellow3: #cc872b,
  // rgba(204, 135, 43, 1); yellow
  midYellow: #f5b054,
  // rgba(245, 176, 84, 1); mid yellow
  midYellow2: #eba64a,
  // rgba(235, 166, 74, 1); mid yellow
  lightYellow: #fbf3e9,
  // rgba(251, 243, 233, 1); light yellow
  lightYellow2: #fbf2e9,
  // rgba(251, 242, 233, 1); light yellow
  lightYellow3: #fff8f0,
  // rgba(255, 248, 240, 1); light yellow
  lightYellow4: #fff1fc,
  // rgba(255, 241, 252, 1); light yellow
  blue: #6cb4f5,
  // blue
  midBlue: #7c8bff,
  // rgba(124, 139, 255, 1); mid blue
  midBlue2: #7281f5,
  // rgba(114, 129, 245, 1); mid blue
  midBlue3: #5463ff,
  // rgba(84, 99, 255, 1); mid blue
  midBlue4: #7785f3,
  // rgba(119, 133, 243, 1); mid blue
  darkBlue: #6877ff,
  // rgba(104, 119, 255, 1); dark blue
  blueTinge: #f7f8fb,
  // rgba(247, 248, 251, 1); tinge of blue
  lightBlue: #b8c7ff,
  // rgba(184, 199, 255, 1); light blue
  lightBlue2: #eaedff,
  // rgba(234, 237, 255, 1); light blue
  lightBlue3: #f5f6ff,
  // rgba(245, 246, 255, 1); light blue
  lightBlue4: #f9faff,
  // rgba(249, 250, 255, 1); light blue-grey
  lightBorderBlue: #f1f2fb,
  // rgba(241, 242, 251, 1); light blue
  darkGreen: #009c06,
  // rgba(0, 156, 6, 1); dark green
  darkGreen2: #007e00,
  // rgba(0, 126, 0, 1); dark green
  lightGreen: #edf5f1,
  // rgba(237, 245, 241, 1); light green
  lightGreen2: #f2faf5,
  // rgba(242, 250, 245, 1); light green
  red: #e10815,
  // red
  darkRed: #9e0000,
  // rgba(158, 0, 0, 1); dark red
  lightRed: #fbeaea,
  // rgba(251, 234, 234, 1); light red
  midRed: #e74c3c,
  // rgba(231, 76, 60, 1); mid red
  alertBorderRed: #fbe6e5,
  // rgba(251, 230, 229, 1); alert border red
  alertBgRed: #fdf6f6,
  midTeal: #009da9,
  // rgba(0, 157, 169, 1); mid teal
  lightTeal: #eafaff,
  // rgba(234, 250, 255, 1); light teal
  componentBgGray: #c2cbd9,
  deepSkyBlue: #3d7dff,
  softBlue: #7c8bff,
  amberColor: #ffba5e,
  periwinkle: #a4b3ff,
  paleCyan: #9ad8d8,
  lightAzureColor: #83c7ff,
  ceruleanBlue: #3d7dff,
  deepPeriwinkleBlue: #5c5ce5,
  pastelPeriwinkle: #a29bfe,
  darkCornflowerBlue: #483ebd,
  lightRoyalBlue: #7f9cf5,
  vermilion: #e54c4c,
  fuchsiaPink: #ff5a89,
  pastelPink: #ffa0a0,
  deepRose: #b82f67,
  salmon: #f97850,
  lightSalmon: #ffb8a1,
  saffron: #f4c222,
  viridian: #0f936e,
  aquaFoam: #64b9a1,
  turquoise_new: #33cccc,
  darkCyan: #109d9d,
  gainsboro: #d4dbd9,
  mediumaquamarine: #5fac86,
  whitesmoke100: #f2f3f6,
  aliceblue: #e4ecf7,
  steelblue: #505780,
  tableGrayRowBg: #f7f7f7,
  gray100: #fbfbfb,
  border200gray: #e5e7eb,
  borderzinc500: #71717a,
  bgzinc100: #f4f4f5,
  colorzinc400: #a1a1aa,
  selectBorderColor: #d1d5db,
  blue73: #cfd5ff73,
  formulaColor: #8795ff,
  cobaltBlue: #3672f4,
  mistyBlue: #4e648ab0,
  wintermist: #f6f6f8,
  mediumGray3: #bbb,
  carbon400: #bcbdc6,
  oceandusk: #7195df,
  eggshell: #f4f4f6,
  turquoise: #79c7ff,
  seafoam: #78c8b0,
  ghostWhite: #f0f0f0,
  slateGray: #818181,
  midBlueOpac: #b8c7ff80,
  lavendeGray: #e6e7ed,
  charcoalGray: #3c3d43,
  gainsboro2: #d7d8e0,
  magnolia: #f6f4fa,
  whisperBlue: #f5f5ff,
  brilliantBlue: #5462ff,
  periwinkle2: #0000be,
  peach: #e85f25,
  shockingPink: #e825be,
  lightCoral: #fe8640,
  lavenderPurple: #fe404a,
  mayaBlue: #b540fe,
  mellowApricot: #ffb95d,
  periwinkleFrost: #e9e9ff,
  vividSkyBlue: #40acfe,
  shamrockGreen: #21cc57,
  lavenderWeb: #f0f0f5,
  periwinkleGray: #e6e7ed,
  lightPeriwinkle: #d5dae3,
  culturedGray: #fafafc,
  manatee: #81828b,
  lightMidBlue: #b8c7ff26,
  black2024: #202024,
  darkOrange: #feb65b,
  grayBg2: #f5f5fa,
  grayBorder3: #e7e7e9,
  veryLightBlue1: #e9e9ff,
  darkBlue3: #0000be,
  veryLightBlue2: #e9e9ff,
  lighterBlue: #c8c9ff,
  brightBlue: #5462ff,
  veryPaleOrange: #fff4e2,
  vividOrange: #feaa40,
  veryPaleOrange2: #fff4e2,
  lightOrange: #ffe1b8,
  vividOrange2: #feaa40,
  veryLightPink: #ffe2f9,
  brightPink: #fe40d5,
  veryLightPink2: #ffe2f9,
  lightPink1: #ffb8f0,
  brightPink2: #fe40d5,
  veryPaleRed: #ffede2,
  vividOrangePeel: #fe8640,
  veryPaleRed2: #ffede2,
  lightRedOrange: #ffd2b8,
  vividOrangePeel2: #fe8640,
  veryLightRed: #ffe2e3,
  brightRed: #fe404a,
  veryLightRed2: #ffe2e3,
  lightRed3: #ffb8bc,
  brightRed2: #fe404a,
  veryPalePurple: #f4e2ff,
  brightPurple: #b540fe,
  veryPalePurple2: #f4e2ff,
  lightPurple: #e4b8ff,
  brightPurple2: #b540fe,
  veryPaleBlue: #e2f2ff,
  brightBlueSky: #40acfe,
  veryPaleBlue2: #e2f2ff,
  lightCornflowerBlue: #b8e0ff,
  brightBlueSky2: #40acfe,
  veryPaleGreen: #e2ffeb,
  strongMintGreen: #21cc57,
  paleGreen1: #b7ffcf,
  softGreen: #83fcaa,
  vividGreen: #38e06d,

  desaturatedBlue: #a1a5ff,
  paleOrange: #ffcd8b,
  livelyPink: #ff8be5,
  softCoralPink: #ff999e,
  bluish: #d48dff,
  cheerfulBlue: #76c3ff,
  lightYellow5: #ffd83b,
  aiOptionBg: #fff1de,
  modalOcerlayColor: #0505079b,

  midBlueDark: #465afb,
  midBlueLight: #9facf9,
  orangeLight: #ffca85,

  blue400: #707cff,
  orange400: #feb65b,
  pink400: #ed68ce,
  black500: #9c9da6,
);

$cellConfig: (
  cellWidth: $cell-width,
  cellHeight: $cell-height,
  sidebarCellWidth: $sidebar-cell-width,
  sidebarAdditionalWidthFinancials: $sidebar-additional-width-financials,
  sidebarAdditionalWidthModels: $sidebar-additional-width-models,
  sidebarAdditionalWidthHP: $sidebar-additional-width-hp,
  sidebarCellAdditionalIdent: $sidebar-cell-additional-ident,
  minOutputCellWidth: $min-output-cell-width,
);

@function color($key) {
  @return map.get($colors, $key);
}

@function cellConfig($key) {
  @return map.get($cellConfig, $key);
}

:export {
  selectBorderColor: $select-border-color;
  duskGray: $dusk-gray;
  black: $global-header-color-deepNavy;
  lightGrey: $light-grey-2;
  white: $white-color;
  greyWhite: $background-color;
  foregroundColor: $loader-foreground-color;
  midBlue: $mid-blue;
  formulaOptionShadow: $formula-option-shadow;
  slateGray: $slate-gray;
  periwinkle2: color(periwinkle2);
  peach: color(peach);
  shockingPink: color(shockingPink);
  lightCoral: color(lightCoral);
  lavenderPurple: color(lavenderPurple);
  mayaBlue: color(mayaBlue);
  mellowApricot: color(mellowApricot);
  periwinkleFrost: color(periwinkleFrost);
  vividSkyBlue: color(vividSkyBlue);
  shamrockGreen: color(shamrockGreen);
  formulaColor: color(formulaColor);
  stripeColor: $stripeColor;
  cellBorderColor: $cellBorderColor;
  aiOptionBg: color(aiOptionBg);
  lavendeGray: color(lavendeGray);
  culturedGray: color(culturedGray);
  whisperBlue: color(whisperBlue);
  // TemplatesCardColor
  veryLightBlue1: color(veryLightBlue1);
  darkBlue3: color(darkBlue3);
  veryLightBlue2: color(veryLightBlue2);
  lighterBlue: color(lighterBlue);
  brightBlue: color(brightBlue);

  veryPaleOrange: color(veryPaleOrange);
  vividOrange: color(vividOrange);
  veryPaleOrange2: color(veryPaleOrange2);
  lightOrange: color(lightOrange);
  vividOrange2: color(vividOrange2);

  veryLightPink: color(veryLightPink);
  brightPink: color(brightPink);
  veryLightPink2: color(veryLightPink2);
  lightPink1: color(lightPink1);
  brightPink2: color(brightPink2);

  veryPaleRed: color(veryPaleRed);
  vividOrangePeel: color(vividOrangePeel);
  veryPaleRed2: color(veryPaleRed2);
  lightRedOrange: color(lightRedOrange);
  vividOrangePeel2: color(vividOrangePeel2);

  veryLightRed: color(veryLightRed);
  brightRed: color(brightRed);
  veryLightRed2: color(veryLightRed2);
  lightRed3: color(lightRed3);
  brightRed2: color(brightRed2);

  veryPalePurple: color(veryPalePurple);
  brightPurple: color(brightPurple);
  veryPalePurple2: color(veryPalePurple2);
  lightPurple: color(lightPurple);
  brightPurple2: color(brightPurple2);

  veryPaleBlue: color(veryPaleBlue);
  brightBlueSky: color(brightBlueSky);
  veryPaleBlue2: color(veryPaleBlue2);
  lightCornflowerBlue: color(lightCornflowerBlue);
  brightBlueSky2: color(brightBlueSky2);

  veryPaleGreen: color(veryPaleGreen);
  strongMintGreen: color(strongMintGreen);
  paleGreen1: color(paleGreen1);
  softGreen: color(softGreen);
  vividGreen: color(vividGreen);
  lightOrange: color(lightOrange);

  desaturatedBlue: color(desaturatedBlue);
  paleOrange: color(paleOrange);
  livelyPink: color(livelyPink);
  softCoralPink: color(softCoralPink);
  bluish: color(bluish);
  cheerfulBlue: color(cheerfulBlue);
  lightYellow5: color(lightYellow5);
  midPink: color(midPink);
  blue: color(blue);
  modalOcerlayColor: color(modalOcerlayColor);
  // Global Cells
  cellWidth: $cell-width;
  cellHeight: $cell-height;
  sidebarCellWidth: $sidebar-cell-width;
  sidebarAdditionalWidthFinancials: $sidebar-additional-width-financials;
  sidebarAdditionalWidthModels: $sidebar-additional-width-models;
  sidebarCellAdditionalIdent: $sidebar-cell-additional-ident;
  outputCellSelectWidth: $output-cell-select-width;
  grayBg2: color(grayBg2);
  // Graphs Colors
  midBlueDark: color(midBlueDark);
  midBlueLight: color(midBlueLight);
  orangeLight: color(orangeLight);
  lightCyan: color(lightCyan);
  manatee: color(manatee);
  red: color(red);
  shamrockGreen: color(shamrockGreen);
  midBlue3: color(midBlue3);
  darkCornflowerBlue: color(darkCornflowerBlue);
  vermilion: color(vermilion);
  deepRose: color(deepRose);
  midBlueOpac: color(midBlueOpac);
  green: color(green);
  blue400: color(blue400);
  orange400: color(orange400);
  pink400: color(pink400);
  grayBg2: color(grayBg2);
  tableBorderColor: color(tableBorderColor);
  blueTinge: color(blueTinge);
  seafoam: color(seafoam);
}

$colorPalette1: color(midBlue);
$colorPalette2: color(yellow2);
$colorPalette3: color(lightViolet);
$colorPalette4: color(lightCyan);
:export {
  colorPalette1: $colorPalette1;
  colorPalette2: $colorPalette2;
  colorPalette3: $colorPalette3;
  colorPalette4: $colorPalette4;
  fontFamily: $font-family;
  brilliantBlue: color(brilliantBlue);
}
