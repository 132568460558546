@import '../../styles/scss/abstracts/variables.module';
@import '../../styles/scss/helper.scss';

.donutGraph {
  height: 255px;
}
.otherGraph {
  height: 225px;
}
.otherGraphWithoutHighlightsDonut {
  height: 305px;
  position: relative;
  left: 22px;
}

.otherGraphWithoutHighlightsDonutWithTabs {
  height: 280px;
  position: relative;
  left: 22px;
}

.otherGraphWithoutHighlights {
  height: 305px;
}
.addChartDonutGraph {
  height: 300px;
  position: relative;
  left: 18px;
}
.donutGraphAfterHighlights {
  height: 220px !important;
  position: relative;
  left: -21px;
}
.addChartDonutGraphAfterHighlights {
  height: 188px !important;
}
.addChartOtherGraph {
  height: 267px;
}
.addChartOtherGraphWithoutSeriesData {
  height: 307px;
  // width: 620px !important;
}
.chartWidth {
  width: 578px;
}
.chartWrapper {
  @apply flex;
  height: inherit;
}
.ChartHeightWidth {
  position: relative;
  z-index: 100;
  height: 100%;
  width: 100%;

  .customChart {
    position: relative;
    left: -16px;
  }

  .customDonut {
    position: relative;
    left: -42px;
  }
}
.chartTableWrapper {
  @apply w-full flex-col overflow-auto;
}
.chartTableWrapperWithHighlights {
  @apply min-w-[298px];
}
.chartTableWrapperDonut {
  @apply relative;
  left: -48px;
}
.chartTableWrapperWithoutHighlights {
  @apply relative;
  left: -26px;
  max-height: 250px;
}
.chartTableTitle {
  @extend .fs-14-600;
  color: color(black);
}

.chartTableMiddle {
  color: color(black);
  white-space: nowrap;
  @include textStyle(14px, 600);
}

.chartTableTitleBorder {
  @apply flex items-baseline justify-between h-[25px] gap-4 mb-4;
  border-bottom: 1px solid color(blueTinge);
  width: inherit;
}
.chartTableTextWrapper {
  @apply flex items-baseline justify-between py-1;
  .chartTableText {
    @apply truncate;
    @extend .fs-14-500;
    color: color(grey);
  }
}
.chartBgWrapper {
  @apply p-4;
  @apply rounded-2xl;
  background-color: color(blueTinge);
  .chartBg {
    background: url('/assets/images/box_bg.svg');
    background-size: 78px 58px;
    height: 100%;
    width: 100%;
    background-repeat: space;
  }
}
.graphMenu {
  @apply flex w-full cursor-pointer items-center whitespace-nowrap rounded py-2 text-xs text-gray-900 hover:bg-gray-200 focus:outline-none;
}
.renderChart {
  @apply rounded-[0.5rem] bg-white;
}
:export {
  fontFamily: $font-family;
  softBlue: color(softBlue);
  amberColor: color(amberColor);
  periwinkle: color(periwinkle);
  paleCyan: color(paleCyan);
  lightAzureColor: color(lightAzureColor);
  ceruleanBlue: color(ceruleanBlue);
  deepPeriwinkleBlue: color(deepPeriwinkleBlue);
  pastelPeriwinkle: color(pastelPeriwinkle);
  darkCornflowerBlue: color(darkCornflowerBlue);
  lightRoyalBlue: color(lightRoyalBlue);
  vermilion: color(vermilion);
  fuchsiaPink: color(fuchsiaPink);
  pastelPink: color(pastelPink);
  deepRose: color(deepRose);
  salmon: color(salmon);
  lightSalmon: color(lightSalmon);
  saffron: color(saffron);
  viridian: color(viridian);
  aquaFoam: color(aquaFoam);
  turquoise: color(turquoise);
  darkCyan: color(darkCyan);
  midBlue: color(midBlue);
  lightMidBlue: color(lightMidBlue);
}

.donutChartContainer {
  position: relative;
  z-index: 100;
  left: -30px;
}
