@import '../../../../styles/scss/abstracts/variables.module';

.payment-form {
  width: 100%;
  min-width: 500px;
  align-self: top;
  padding: 40px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}

.payment-message {
  color: color(gray);
  @include textStyle(16px, 400);
  padding-top: 12px;
  text-align: center;
}

.payment-message-error {
  color: color(red);
  @include textStyle(16px, 400);
  padding-top: 12px;
  text-align: center;
}

.payment-element {
  margin-bottom: 24px;
}

.promo-code {
  margin-bottom: 24px;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
  width: 100%;
  padding: 12px 16px;
  border: 1px solid color(lightestGrey);
  border-radius: 4px;
  @include textStyle(16px, 400);
  height: 45px;
}

.payment-button {
  background: color(midBlue);
  color: color(white);
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  @include textStyle(16px, 600);
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.apply-promo {
  background: color(midBlue);
  color: color(white);
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  @include textStyle(16px, 600);
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment-button:hover {
  filter: contrast(115%);
}

.payment-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.stripePopupContainer {
  width: 780px;
  height: 'auto';
  min-height: 510px;
  border-radius: 16px;
  background-color: color(culturedGray);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: top;
  overflow-y: auto;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.stripePopupTextContainer {
  width: 100%;
  // margin-top: auto;
  margin-bottom: 15px;

  h1 {
    @include textStyle(22px, 600);
    color: color(black);
    text-align: center;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  p {
    margin-top: 24px;
    @include textStyle(16px, 400);
    color: color(gray);
    text-align: left;
  }
}

.choosePlanContainer {
  width: 780px;
  height: 'auto';
  min-height: 510px;
  border-radius: 16px;
  background-color: color(culturedGray);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: top;
  overflow-y: auto;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding: 24px;

  .choosePlanTextContainer {
    h1 {
      @include textStyle(22px, 600);
      color: color(black);
      text-align: left;
    }

    p {
      @include textStyle(16px, 400);
      color: color(gray);
      text-align: left;
    }

    margin-bottom: 24px;
  }

  .choosePlanFeaturesContainer {
    width: 100%;
    height: 100%;
    background-color: color(white);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    @apply shadow-md;

    .featuresHeader {
      @include textStyle(14px, 600);
      color: color(black);
      margin-bottom: 5px;
    }

    .choosePlanDescription {
      @include textStyle(14px, 400);
      color: color(gray);
      padding-top: 10px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 5px;
      border: none;
      outline: none;
      box-shadow: none;

      li {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        padding: 0;

        svg {
          color: color(midBlue);
        }

        p {
          @include textStyle(14px, 400);
          color: color(black);
          margin-top: 0;
        }

        &:hover {
          background-color: transparent;
          color: color(black);
        }
      }
    }
  }

  .choosePlanButtonContainer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    button {
      background: color(midBlue);
      color: color(white);
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      @include textStyle(16px, 500);
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
      width: 100%;
      @apply shadow-sm;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    button:nth-child(1) {
      background: transparent;
      color: color(black);
      border: 1px solid color(lightestGrey);
    }
  }
}

.carouselContainer {
  width: 260px;

  .slide {
    padding: 5px;
    .testimonialCard {
      width: 100%;
      height: 200px;

      background-color: color(white);
      @apply shadow-md;
      border-radius: 5px;
      padding: 10px;
      border: 1px solid color(lightestGrey);

      .testimonialCardHeader {
        display: flex;
        align-items: center;
        gap: 10px;

        .testimonialCardHeaderLeft {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .testimonialCardHeaderRight {
          h1 {
            @include textStyle(14px, 600);
            color: color(black);
          }

          p {
            @include textStyle(12px, 400);
            color: color(gray);
          }
        }
      }

      .testimonialCardBody {
        margin-top: 10px;
        p {
          @include textStyle(12px, 400);
          color: color(black);
          word-break: break-word;
          overflow-wrap: break-word;
        }
      }
    }
  }
}

.money-back-guarantee {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: color(gray);
  @include textStyle(16px, 400);
  margin-top: 10px;
}

.iconsSection {
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h1 {
    @include textStyle(16px, 600);
    color: color(black);
  }
}

.logoutButton {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  @include textStyle(14, 500);

  svg {
    color: color(black);
  }

  &:hover {
    svg {
      color: color(midBlue);
    }

    span {
      color: color(midBlue);
    }
  }
}
