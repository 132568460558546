@import '../../../styles/scss/abstracts/variables.module';

.showAllUsageModalContent {
  padding: 12px 14px 12px 14px;
  max-height: inherit;
  overflow-y: auto !important;
  width: 100%;
  height: 100%;

  .showAllUsageModalNoItem {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: color(gray);
    @include textStyle(14, 400);
  }

  .showAllUsageModalItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap !important;
    border-radius: 8px;
    color: color(black);
    padding: 0px 16px 0px 30px !important;
    height: 50px !important;
    @include textStyle(13, 400);
    font-family: monospace !important;
    position: relative;

    .showAllUsageModalItemIcon {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

    .showAllUsageModalItemModel {
      color: color(gray);
      @include textStyle(13px, 400);
      font-family: monospace !important;
      max-width: 100px;
      overflow: hidden;
      flex: grow;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      right: 10px;
      top: 10%;
      transform: translateY(-10%);
    }

    &:hover {
      cursor: pointer;
      background-color: color(bgHoverList);
    }

    .showAllUsageModalItemContent {
      display: flex;
      flex-direction: column;

      span {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include textStyle(13px, 500);
        font-family: monospace !important;
      }

      .showAllUsageModalItemGroup {
        color: color(gray);
        @include textStyle(13px, 400);
        font-family: monospace !important;
        font-style: italic;
      }
    }
  }
}
