@import '../../../../../../styles/scss/abstracts/variables.module';
@import '../../../../../../styles/scss/abstracts/mixins';

.tableHeaderContainer {
  height: 84px;
  width: 100%;
  background-color: color(white);
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;

  .tableHeaderLeftSide {
    padding: 14px 0px 8px 28px;
    display: flex;
    min-width: 300px;
    gap: 30px;
    margin-right: 20px;
    align-items: center;
    height: 100%;

    h1 {
      color: color(charcoalGray);
      @include textStyle(20px, 500);
    }

    svg {
      color: color(charcoalGray);
      cursor: pointer;
    }
  }

  .tableHeaderRightSide {
    padding: 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .tableHeaderSearch {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        color: color(selectBorderColor);
      }

      input {
        height: 44px;
        border-radius: 24px;
        border: 1px solid color(selectBorderColor);
        width: 100%;
        padding: 10px 16px;
        padding-left: 48px;
        @include textStyle(14px, 500);
        color: color(black);

        &::placeholder {
          color: color(selectBorderColor);
        }
      }
    }
  }
}
