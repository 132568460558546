// Import colors from TypeScript file
@import './abstracts/variables.module';

.dataCellPickerContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: color(lightBlue4);
    border-color: color(midBlue);
  }
  &:focus {
    border: 1px solid;
    border-color: color(midBlue);
  }

  .dataCellPicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      cursor: pointer;

      path {
        transition: all 0.3s ease-in-out;
        stroke: color(disabledText);
      }

      &:hover {
        path {
          stroke: color(black);
        }
      }
    }

    .rightArrow {
      svg {
        transform: rotate(180deg);
      }
    }

    .disabled {
      svg {
        cursor: not-allowed;

        path {
          stroke: color(disabledText);
        }
      }
    }

    .dateText {
      @include textStyle(12px, 400);
      color: color(black);
      cursor: pointer;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 68px;
      min-height: 48px;
    }
  }
}

.resetButtonContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 5px;
}

.resetButton {
  background-color: color(selectedDateBg);
  color: color(white);
  @include textStyle(12px, 400);
  padding: 4px 8px;
  border-radius: 6px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
