@import '../../../../../styles/scss/abstracts/variables.module';

.lineImage {
  @apply relative object-cover;
}

.dialog {
  @apply outline-none border-none flex items-center rounded-sm;
}

.calendarContainer {
  @apply flex cursor-pointer items-center gap-[6px] bg-white p-0;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  box-shadow: 0px 16px 32px 0px rgba(23, 37, 76, 0.12);
}

.calendarHeaderContainer {
  @apply flex items-center;
  margin-bottom: 12px;
  width: 100%;
}

.calendarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span:first-child {
    margin-right: 6px;
    font-weight: 500;
  }

  .arrows {
    display: flex;
    align-items: center;

    img:first-child {
      padding-left: 8px;
    }

    .disabledArrow {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .leftArrow {
      transform: rotate(180deg);
    }
  }
}

.title {
  @apply font-medium text-lg;
}
.modalPadding {
  @apply p-3;
}
.displayFlex {
  @apply flex;
  border: none !important;
  align-items: center;
  gap: 5px;
}

.flex1 {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 5px;

  border: none !important;
}

.dialogButton {
  white-space: nowrap;
  @apply outline-none border-none flex items-center bg-white pl-4 pr-3 py-2 rounded-lg h-10;
  img {
    @apply ms-[10px] shrink-0 h-4 w-4;
  }
}

.grayDialogButton {
  border: 1px solid color(gainsboro2);
  background-color: transparent;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 32px;
  gap: 5px;

  @include textStyle(14px, 500);
}

.rotationClass {
  @apply rotate-180;
}

.subHeaderInput {
  background-color: color(lightGrey);
  max-width: 6rem;
  padding: 5px;
  text-align: center;
  box-sizing: content-box;
}

.currentDateHeader {
  color: #293050;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.18px;
}

.todayBtn {
  @include textStyle(14px, 500);
  color: color(midBlue);
  border-radius: 8px;
  padding: 5px 10px;
  border: 1px solid color(midBlue);
  margin-right: 40px;

  &:active {
    border-color: color(manatee);
    color: color(manatee);
  }

  &:disabled {
    border-color: color(manatee);
    color: color(manatee);
    cursor: not-allowed;
  }
}

.currentDatePicker {
  width: 160px !important;
  justify-content: space-between !important;
  padding: 0 !important;
}
.popover {
  width: max-content;
  padding: 0.5rem;
}

.applyButton {
  @include textStyle(14px, 500);
  color: color(midBlue);
  border-radius: 8px;
  padding: 5px 10px;
  border: 1px solid color(midBlue);
  transition: all 0.3s;

  &:hover {
    background-color: color(midBlue);
    color: color(white);
  }

  &:active {
    border-color: color(manatee);
    color: color(manatee);
  }

  &:disabled {
    border-color: color(manatee);
    color: color(manatee);
    cursor: not-allowed;
  }
}
