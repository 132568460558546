.confettiContainer {
  position: absolute;
  width: 100vw;
  height: 100vw;
  background-color: transparent;
  z-index: 1000;

  .leftTopConfetti {
    position: fixed;
    top: -10px;
    left: -10px;
  }

  .rightTopConfetti {
    position: fixed;
    top: -10px;
    right: -10px;
  }
}
