@import '../styles/scss/abstracts/variables.module';

@mixin flex(
  $direction: row,
  $align: stretch,
  $justify: initial,
  $gap: initial
) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  gap: $gap;
}

@mixin modalStyles($width, $height) {
  overflow: auto;
  background-color: white;
  width: $width;
  height: $height;
}

@mixin buttonStyles(
  $bg-color,
  $text-color,
  $padding,
  $radius,
  $font-size,
  $font-weight
) {
  background-color: $bg-color;
  color: $text-color;
  padding: $padding;
  border-radius: $radius;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin containerStyles {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.tabPanelContainer {
  @include flex(column, stretch, initial, 1.5rem);
  padding-bottom: 20px;

  .templateItem {
    width: 100%;
    background-color: color(white);
    border-radius: 24px;
    padding: 32px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

      .templateOptions {
        display: flex;
      }
    }

    .mid {
      @include flex(row, initial, space-between, 0rem);

      .container {
        width: 100%;
        @include containerStyles;

        .slugContainer {
          display: flex;
          align-items: center;
          gap: 1em;

          .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px 7px 3px 4px;
            background-color: color(whisperBlue);
            border-radius: 4px;
            gap: 5px;
          }
        }

        .ul {
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          box-shadow: none;

          li {
            padding: 0;
            border: 0;
            &:hover {
              background-color: color(whisperBlue);
              cursor: initial;
              color: initial;
            }
            .tag {
              margin-right: 0.5rem;
              white-space: nowrap;
              color: color(brilliantBlue);
            }
          }
        }

        .text {
          @include containerStyles;
          margin-top: 0.5rem;

          h3 {
            @include textStyle(20px, 700);
            line-height: 24px;
            color: color(charcoalGray);
          }

          p {
            margin-top: 15px;
            @include textStyle(20px, 500);
            line-height: 24px;
            color: color(charcoalGray);
            max-width: 60%;
          }

          .updatedDetail {
            color: color(slateGray) !important;
            @include textStyle(14px, 500);
            margin-top: 0;
          }
        }
      }
    }

    .bottom {
      @include flex(row, initial, initial, 0);
      flex-wrap: wrap;
      margin-top: 30px;
    }
  }
}

.noTemplates {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;

  p {
    @include textStyle(18px, 500);
    color: color(slateGray);
  }
}

.templateDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    box-shadow: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    li {
      &:hover {
        background-color: transparent;
        cursor: initial;
        color: initial;
        border: none;
      }

      color: color(slateGray);
    }
  }

  .templateOptions {
    display: none;
    .deleteIcon {
      cursor: pointer;

      &:hover {
        color: color(red) !important;
      }
    }
  }
}

.sectionHeader {
  color: color(charcoalGray);
  @include textStyle(16px, 800);
  margin-bottom: 20px;
}

.header {
  @include flex(row, flex-start, space-between);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom: 1px solid #d1d5db;
  background-color: #fff;
  padding: 0.75rem;

  .container {
    @include flex(row, center);
    font-size: 1rem;
    line-height: 2rem;
    letter-spacing: 0.01em;
    color: #111827;
  }

  .aiOutline {
    cursor: pointer;
    font-size: 2rem;
    color: #6b7280;

    &:hover {
      color: #4b5563;
    }
  }
}

.modal {
  @include modalStyles(70vw, 90vh);
  position: relative;
}

.flexContainer {
  @include flex(column, stretch);
  overflow: auto;
  width: 100%;
  button:disabled {
    cursor: not-allowed;
  }
}

.tabList {
  @include flex(row, initial, initial, 1rem);
  gap: 5px;

  .tab {
    padding: 8px;
    border-radius: 6px;
    @include textStyle(14px, 500);
    cursor: pointer;
    color: color(charcoalGray);
    outline: none;
    width: max-content;

    &[data-selected='true'] {
      background: color(lavendeGray);
      padding: 8px;
      border-radius: 6px;
    }

    &:hover {
      background: color(lavendeGray);
      padding: 8px;
      border-radius: 6px;
    }
  }
}

.pageHeaderText {
  @include textStyle(32px, 500);
  color: color(black);
}

.searchTemplatesInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-right: 10px;
  width: 100%;
  max-width: 450px;

  .searchInputIcon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: color(carbon400);
  }

  .clearIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: color(carbon400);
    cursor: pointer;
  }

  input {
    border: 1px solid color(lavendeGray);
    padding: 10px 16px 10px 38px;
    height: 40px;
    border-radius: 24px;
    @include textStyle(14px, 500);
  }
}

.searchIcon {
  margin-right: 10px;
  cursor: pointer;
  height: 40px;
}

.addTemplateButton {
  background-color: color(darkOrange) !important;
  padding: 8px 16px 8px 16px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @include textStyle(14px, 500);
  min-width: 145px;

  .plusIcon {
    margin-left: 5px;
  }
}

.template {
  background-color: color(magnolia);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 40px 24px;
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 1200px;
    padding: 40px;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    padding: 40px 120px;
  }

  @media (min-width: 1920px) {
    max-width: 1920px;
  }
}

.details {
  height: calc(100% - 7.75em);
  @include flex(column, flex-start, flex-start);
  overflow: auto;
  padding-bottom: 1.25em;
  h3 {
    font-weight: 500;
  }

  .container,
  .templateName {
    max-width: 48em;
    padding: 1em;
    background: #f6f8fa;
    margin: 1em auto 0;
    width: 95%;
    align-self: center;

    h3 {
      margin-bottom: 1em;
    }
    ul {
      box-shadow: none !important;
      *:hover {
        background: transparent;
        cursor: initial;
        color: initial;
      }
    }
    p,
    li {
      padding: 0em;
      border-radius: 0em;
      font-size: 0.8em;
    }

    &.month {
      @include flex(row);
    }

    .monthText,
    .monthElement {
      padding: 1em;

      span {
        font-weight: 500;
      }
    }
  }
}

.button-panel {
  padding: 1em;
  @include flex(row, center, flex-end, initial);
  border-top: 1px solid #eee;
  gap: 1em;
  width: 100%;

  * {
    font-size: 0.85em;
  }
}

.continue {
  @include buttonStyles(#3d7dff, white, 0.5em 1em, 0.25em, 0.8em, 500);
}

.remove {
  @include buttonStyles(transparent, #e54c74, 0.5em 1em, 0.25em, 0.8em, 500);
  border: 1px solid #eee;
}

.templateDetails {
}

.templateName {
  background: transparent !important;
  padding: 1em 0 0 !important;
  text-transform: capitalize;
}

.mosdtUsedTemplateCard {
  padding: 24px;
  background: color(white);
  border-radius: 24px;
  width: 31%;
  min-width: 200px;
  max-height: 108px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .templateCardHeader {
    h3 {
      @include textStyle(14px, 700);
      line-height: 21.6px;
      color: color(charcoalGray);
    }
  }

  .hoverEffect {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    button {
      background-color: color(mellowApricot);
      padding: 10px 16px 10px 16px;
      border-radius: 8px;
      @include textStyle(14px, 500);
      color: color(charcoalGray);

      &:disabled {
        background-color: lighten(color(mellowApricot), 10%);
        cursor: not-allowed;
      }
    }
  }

  &:hover {
    .hoverEffect {
      opacity: 1;
    }
  }

  .cube {
    width: 40px;
    height: 40px;
    border-radius: 7.62px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.templateHoverCard {
  pointer-events: none;
  width: 441px;
  height: auto;
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 101;

  .templateHoverCardDetail {
    width: 441px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 40px;
    padding-left: 39px;
    padding-top: 46px;
    padding-bottom: 46px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      @include textStyle(30px, 700);
      line-height: 30px;
      color: color(charcoalGray);
      width: 70%;
    }

    ul {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 20px;

      li {
        padding: 0;
        margin: 0;
        @include textStyle(14px, 500);
      }
    }
  }
}

.inActive {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
