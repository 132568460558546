.aiContainer {
  @apply flex  px-5 rounded-md items-center bg-white shadow-lg h-10;
  font-family: 'Figtree';
  display: inline-flex;
  flex-wrap: nowrap;
  position: relative;
}

.firstContainer {
  @apply flex w-auto;
}
.title {
  @apply mr-2;
  width: 'fit-content';
}

.aiItem {
  @apply p-1 text-[12px] rounded-sm flex items-center justify-between;
  margin-right: 5px;
  height: 22px;
}

.optionIcons {
  padding: 0 5px;
  @apply flex items-center justify-between w-[120px];
}

.optionIcons img {
  @apply w-5 h-5 cursor-pointer;
}
.optionIcons img:hover {
  @apply bg-iconHoverColor rounded-full;
}

.optionIcons:last-child {
  @apply cursor-pointer;
}

.button {
  width: 21px;
  height: 21px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: red;
  border-radius: 50%;
}

.animationWrapper {
  @apply rounded-md;
  display: inline-flex;
  align-items: center;
}
