@import '../scss/abstracts/variables.module';

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  @apply mt-1 mb-1;
  padding: 5px !important;
}
.react-calendar__navigation button:disabled {
  background-color: $global-header-color-white !important;
}

.react-calendar__tile--hasActive {
  background: none !important;
}

.react-calendar {
  width: 215px !important;
  border: 0 !important;
}

.subHeader_flex1__fCPOg {
  border-right: 0.02rem solid $border-grey !important;
  border-bottom: 0.02rem solid $border-grey !important;
}
.subHeader_flex1__fCPOg:last-child {
  border-right: none !important;
}
.subHeader_displayFlex__E82_Y {
  border-top: 0.02rem solid $border-grey !important;
}
.react-calendar__navigation {
  border-bottom: 1px solid $border-grey;
}
.react-calendar__navigation button {
  @apply flex items-center justify-center;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}

.calendar-design {
  .react-calendar__tile {
    overflow: visible !important;
    color: color(black);
    width: 38px !important;
    height: 38px !important;
    flex: none !important;
    padding: 0 !important;
    border-radius: 50% !important;
    margin: 8px;
    position: relative;
    z-index: 999 !important;

    &:disabled {
      color: color(monthDisabledColor) !important;
      background-color: transparent;
    }

    &:focus {
      background-color: transparent !important;
      color: color(black);
    }

    &.react-calendar__tile--active {
      background-color: color(selectedDateBg) !important;
      color: color(white) !important;
    }

    &:hover {
      background-color: color(calendarHoverColor) !important;

      &.react-calendar__tile--active {
        background-color: color(selectedDateBg) !important;
        color: color(white) !important;
      }
    }
  }

  .react-calendar__tile--now {
    color: color(black);
    background: inherit !important;
  }

  .react-calendar__tile--within-range {
    background-color: color(selectedMonthBg) !important;
    border-radius: 0 !important;

    &:hover {
      background-color: color(selectedMonthBg) !important;
      color: color(black);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 38px;
      width: 8px !important;
      height: inherit;
      display: block;
      border-radius: 0 !important;
      background-color: color(selectedMonthBg) !important;
      z-index: 1;
    }
  }

  .react-calendar__tile--last-date-from {
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    abbr {
      background-color: color(selectedDateBg) !important;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      box-sizing: content-box;
    }

    &::before {
      content: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      display: block;
      background-color: color(selectedMonthBg) !important;
      z-index: -1;
      border-radius: 0px 18px 18px 0px;

      box-sizing: content-box;
    }

    color: color(white) !important;
    background-color: color(selectedDateBg) !important;
    border-radius: 50% !important;
    position: relative;

    &:disabled {
      color: color(white) !important;
    }
  }

  .react-calendar__tile--first-date-from {
    background-color: color(selectedDateBg) !important;
    color: color(white) !important;
    border-radius: 50% !important;
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    abbr {
      background-color: color(selectedDateBg) !important;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      box-sizing: content-box;
    }

    &:focus {
      outline: none;
      background-color: color(selectedDateBg) !important;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: inherit;
      display: block;
      background-color: color(selectedMonthBg) !important;
      z-index: -1;
      border-radius: 18px 0px 0px 18px;

      box-sizing: content-box;
    }

    // &:hover {
    //   background-color: rgba(24, 30, 48, 0.3) !important;
    //   color: #293050 !important;
    // }
  }

  .react-calendar__tile--last-date-to {
    height: 38px !important;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    abbr {
      background-color: color(selectedDateBg) !important;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      box-sizing: content-box;
    }

    &::before {
      content: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      display: block;
      background-color: color(selectedMonthBg) !important;
      z-index: -1;
      border-radius: 0px 18px 18px 0px;

      box-sizing: content-box;
    }

    color: color(white) !important;
    background-color: color(selectedDateBg) !important;
    border-radius: 50% !important;
    position: relative;
  }

  .react-calendar__tile--first-date-to {
    background-color: color(selectedDateBg) !important;
    color: color(white) !important;
    border-radius: 50% !important;
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    abbr {
      background-color: color(selectedDateBg) !important;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      box-sizing: content-box;
    }

    &:focus {
      outline: none;
      background-color: color(selectedDateBg) !important;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      display: block;
      background-color: color(selectedMonthBg) !important;
      z-index: -1;
      border-radius: 18px 0px 0px 18px;

      box-sizing: content-box;
    }
  }

  .react-calendar__tile--current-date {
    color: color(selectedDateBg) !important;
    font-weight: 600;
    border-radius: inherit;
  }

  .react-calendar__tile--disabled {
    background-color: color(white) !important;
    color: color(monthDisabledColor) !important;
    cursor: not-allowed !important;
  }

  .react-calendar__from-tile--disabled {
    color: color(monthDisabledColor) !important;
    cursor: not-allowed !important;
  }

  .react-calendar__tile--same {
    background-color: color(selectedDateBg) !important;
    color: color(white) !important;

    &::after {
      border-radius: 50%;
    }

    &::before {
      content: none !important;
      display: none !important;
    }
  }
}
