@import '../../../../../styles/scss/abstracts/variables.module';

.outputCellContainer {
  width: 100%;
  min-width: cellConfig(minOutputCellWidth);
  max-width: cellConfig(cellWidth);
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 5px;
  @include textStyle(11px, 400);

  .financialsLabel {
    @include textStyle(11px, 400);
    color: color(black2024);
  }
}
